@use 'sass:math';
@charset "UTF-8";

// Form styles
// See form-items sub–folder for individuals
// ------------------------------------------------------------

.form {
	position: relative;
}

.form-signup-frame {
	@include padding-all(math.div($internal-spacing,2));
	@include respond-to($d-vp) {
		background-image: url(../svg/fallbacks/characters/square/engineer-female.png);
		background-image: url(../svg/characters/square/engineer-female.svg);
		background-size: 200px 200px;
		background-position: 95% 100%;
		background-repeat: no-repeat;
		@include padding-all($internal-spacing);
		@include padding-left($internal-spacing*2);
		@include padding-right($internal-spacing*2);
		html.ie9 &, html.gt-ie9 & {
			background-size: 300px 200px;
			background-position: bottom right;
		}
	}
	@include respond-to($max-vp) {
		html.ie9 &, html.gt-ie9 & {
			background-size: 400px 200px;
		}
	}

	.form-field__radio-buttons--styled {
		label {
			float: left;
			@include space-right(16);
		}
		input[type="radio"]:checked + label {
			color: #fff;
		}
	}
}

// Sections / fieldsets
.form-section {
	@include padding-all($internal-spacing);
	background: #fff;
	border: $default-border-width solid $default-border-color;
}

// Labels
.form-label {
	@include trailer(6);
	font-weight: 400;
	line-height: 1.6;
	&.form-label--hidden {
		display: none;
	}
}

// Form field wrapper
.form-field {
	position: relative;
	@extend %pie-clearfix;
}

// Space the form field
.form-field--spaced {
	@include padding-leader(math.div($internal-spacing,2));
}

// Element paired item
.form-field__pair {
	@extend %pie-clearfix;
	@include respond-to($d-vp) {
		width: 49.5%;
		margin-left: 1%;
		display: inline-block;
		margin-bottom: 0;
		float: left;
		&:first-child {
			margin-left: 0;
		}
	}
	+ .form-field__pair {
		@include respond-to-max($d-vp) {
			@include leader(math.div($internal-spacing,2));
		}
	}
}

// Element triple item
.form-field__triplet {
	@extend %pie-clearfix;
	@include respond-to($d-vp) {
		width: 32.5%;
		margin-left: 1.25%;
		display: inline-block;
		margin-bottom: 0;
		float: left;
		&:first-child {
			margin-left: 0;
		}
	}
	+ .form-field__triplet {
		@include respond-to-max($d-vp) {
			@include leader(math.div($internal-spacing,2));
		}
	}
}

// Standard form elements
.form-item {
	@include padding-all(math.div($internal-spacing,2.5));
	display: block;
	border: 1px solid $default-border-color;
	@include vendor(appearance, none);
	font-weight: 400;
	@include font-size($zeta);
	&::-moz-focus-inner {
	    border: 0;
	    padding: 0;
	}
}

// Get individul items
// -----------------------

// ::Imports. Needed for imported individual form items
// ------------------------------------------------------------


// Radio and checkboxes: Browser standard display
// -----------------------

.form-field__radio-buttons,
.form-field__checkboxes {
	label {
		position: relative;
		top: 0;
		vertical-align: middle;
		font-weight: 400;
		&:after {
			clear: left;
			content: "";
			text-indent: -999em;
			display: block;
			height: 0;
		}
	}
	input {
		float: left;
		margin-right: math.div($internal-spacing-pixel,2);
		vertical-align: middle;
		&:after {
			clear: left;
			content: "";
			text-indent: -999em;
			display: block;
			height: 0;
		}
	}
}

// Radio and checkboxes: Additional style
// -----------------------

:root .form-field__checkboxes--styled,
:root .form-field__radio-buttons--styled {
	min-height: 30px;
	@extend %pie-clearfix;
	input {
		//display: none;
		position: relative;
		z-index: 0;
		top: auto;
		left: -99999em;
		position: absolute;
		&:focus {
			+ label {
				color: $action;
			}
		}
	}
	label {
	    cursor: pointer;
	    vertical-align: top;
	    position: relative;
		z-index: 1;
	}
}

// Checkboxes
:root .form-field__checkboxes--styled {
	label:before {
		content: "";
		display: inline-block;
		vertical-align: text-top;
		width: 22px;
		height: 22px;
		margin-right: 10px;
		margin-top: 2px;
		background-color: #fff;
		border: $default-border-width solid $default-border-color;
		border-radius: 3px;
		float: left;
	}
	input:checked + label:after {
		position: absolute;
		content: "";
		top: 8px;
		left: 5px;
		width: 12px;
		height: 8px;
		border: 3px solid $form-checkbox-color;
		border-top: none;
		border-right: none;
		@include vendor(transform, rotate(-45deg));
		z-index: 3;
	}
}

// Radio
:root .form-field__radio-buttons--styled {
	label:before {
		content: "";
		display: inline-block;
		vertical-align: text-top;
		width: 30px;
		height: 30px;
		margin-right: 10px;
		margin-top: -3px;
		background-color: #fff;
		border: $default-border-width solid $default-border-color;
		border-radius: 50%;
		float: left;
	}
	input:checked + label:after {
		position: absolute;
		content: "";
		top: 2px;
		left: 5px;
		height: 20px;
		width: 20px;
		background: $form-radio-color;
		border-radius: 50%;
		z-index: 3;
	}
}

// ::Imports. Needed for imported individual form items
// ------------------------------------------------------------


// Selects
// -----------------------

.form-field__select-dropdown {
	width: 100%;
	height: $internal-spacing-pixel*1.5;
	overflow: hidden;
	background-color: #fff;
	border: 1px solid $default-border-color;
	display: block;
	position: relative;
	cursor: pointer;
	font-weight: 300;
	overflow: hidden;
	&:hover, &:focus {
		border-color: $action;
		cursor: pointer;
	}
	&:after {
		background: url(../svg/icon-sort.svg) transparent no-repeat center center;
		background-size: 14px 14px;
		height: 100%;
		padding: 1em;
		display: block;
		position: absolute;
		top: 0;
		right: 0em;
		content: ".";
		text-indent: -999999em;
		z-index: 8;
		html.lt-ie9 &, html.ie9 & {
			background: none;
		}
	}
	select {
		background: transparent;
		color: $form-label-color;
		width: 99%;
		padding: 0 math.div($internal-spacing-pixel,2.5) 0 math.div($internal-spacing-pixel,2.5);
		border: 0;
		border-radius: 0;
		height: 100%;
		position: relative;
		z-index: 9;
		vertical-align: middle;
		text-indent: 0.01px;
		text-overflow: '';
		@include vendor(appearance, none);
		font-weight: 400;
		@include font-size($zeta);
		-moz-appearance: window; // Hides firefox native select arrow
		&:hover {
			cursor: pointer;
		}
		&:focus {
			outline: none;
			color: $ds-neptune;
		}
		&::-ms-expand {
			display: none;
		}
		html.ie9 & {
			padding-right: 0;
		}
	}
}

// Mozilla hack for dropdown text placement
@-moz-document url-prefix() {
	.select-dropdown {
		select {
			margin-top: 3px;
		}
	}
}


// Errors
// -----------------------

.form-field__error {
	@include padding-all(math.div($internal-spacing,2));
	@include padding-leader(math.div($internal-spacing,1.25));
	border-left: $default-border-width solid $error;
	background-color: #fff;
	.form-item,
	.form-field__select-dropdown {
		border-color: $error;
		//border-width: 1px;
	}
}

.form-field__error__message {
	color: darken($error, 30%);
	margin-top: 6px;
	font-size: 0.75em;
}

// Warning
// -----------------------

.form-field__warning {
	@extend .form-field__error;
	border-color: $warning;
	.form-item,
	.form-field__select-dropdown {
		border-color: $warning;
	}
}

.form-field__warning__message {
	color: darken($warning, 30%);
	@extend .form-field__error__message;
}

// Success
// -----------------------

.form-field__success {
	@extend .form-field__error;
	border-color: $success;
	.form-item,
	.form-field__select-dropdown {
		border-color: $success;
	}
}

.form-field__success__message {
	color: darken($success, 30%);
	@extend .form-field__error__message;
}
