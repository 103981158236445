@use 'sass:math';

// ------------------------------------------------------------
// Benefits
// ------------------------------------------------------------

.benefit {
	@extend .resource-teaser;
}

// Allow override of extend
a.benefit {
	min-height: auto;
	display: block;
	vertical-align: top;
	@include respond-to-max($d-vp) {
		@include padding-trailer(math.div($internal-spacing,4));
		height: auto;
	}
	@include respond-to($c-vp) {
		min-height: 200px;
		background-image: url(../svg/fallbacks/characters/square/tim-peake.png);
		background-image: url(../svg/characters/square/tim-peake.svg);
		background-size: 100px 100px;
		background-position: 95% 100%;
		background-repeat: no-repeat;
	}
	@include respond-to($d-vp) {
		min-height: 300px;
		background-size: 50% 50%;
		background-position: bottom center;
	}
}

a.benefit--var1 {
	@include respond-to($c-vp) {
		background-image: url(../svg/fallbacks/characters/square/astronaut-female.png);
		background-image: url(../svg/characters/square/astronaut-female.svg);
	}
}

a.benefit--var2 {
	@include respond-to($c-vp) {
		background-image: url(../svg/fallbacks/characters/square/controller-female.png);
		background-image: url(../svg/characters/square/controller-female.svg);
	}
}

.benefit__title {
	color: #fff;
	@include padding-all(math.div($internal-spacing,2));
	@include padding-leader(math.div($internal-spacing,4));
	@include padding-trailer(math.div($internal-spacing,4));
	//@extend .rule;
	//@extend .rule--overline;
	//@extend .rule--white;
	@include rgba($ds-dark-matter, 0.5);
}

.benefit__excerpt {
	@include padding-all(math.div($internal-spacing,2));
	color: #fff;
	line-height: 1.4;
}
