@use 'sass:math';

// ------------------------------------------------------------
// Base styles
// ------------------------------------------------------------

a {
	color: $action;
	text-decoration: none;
	//word-wrap: break-word;
	-webkit-tap-highlight-color: $action;
	@include link-active-styles {
		color: $action-respond;
	}
}

a[href^=tel] {
    color:inherit;
    text-decoration:underline;
}

::selection { background: $action; color: #fff; text-shadow: inherit; }

.skiplink 	{
	background: #fff;
	color: $action;
	border: $default-border-width solid $default-border-color;
	display: block;
	font-size: 16px;
	line-height: 40px;
	padding: 0 20px;
	position: absolute;
	top: -60px;
	left: 0;
	@include vendor(transition, all ease-in-out 0.1s);
	&:focus,
	&:active {
		top: 0;
		z-index: 80;
	}
}

#pagecontent { outline: none; }

// General type controllers
// ------------------------------------------------------------

h1,h2,h3,h4,h5,h6 	{ color: $heading-color; font-weight: 600; }
ul, ol 				{ @include nobullet; }
li 					{ line-height: inherit; }
strong, b 			{ font-weight: 600; }
em, i, q 			{ font-style: italic; }
p,li,h1,h2,h3,h4,h5,h6	{ a { font-size: inherit; }}