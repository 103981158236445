@use 'sass:math';

// ------------------------------------------------------------
// Space personality tool
// ------------------------------------------------------------

.tool {
	position: relative;
}

// Stages
// ------------------------------------------------------------

.tool__stage {
	position: relative;
	@include trailer($base-rhythm);
	@include respond-to($d-vp) {
		@include trailer($base-rhythm*1.5);
	}
}

.tool__stage__label {
	display: block;
	font-weight: 600;
	@include trailer(6);
	@include font-size($eta);
	@extend %is-uppercase;
}

.tool__stage__progress {
	position: relative;
	height: 10px;
	border-radius: 20px;
	background-color: $ds-space-dust;
	overflow: hidden;
	z-index: 0;
}

.tool__stage__progress__bar {
	z-index: 1;
	position: absolute;
	left: 0;
	top: 0;
	height: 10px;
	background-color: $ds-solar;
}


// Section
// ------------------------------------------------------------

.tool__selection {
	position: relative;
	@include trailer($base-rhythm);
	.js & {
		display: none;
	}
}

.tool__selection--scenario {
	@include respond-to($d-vp) {
		@include padding-all($internal-spacing);
		background-color: $ds-space-dust;
	}
}

.tool__selection__header {
	@extend %pie-clearfix;
	position: relative;
}

.tool__selection__label {
	@extend .as-heading-largest;
}

.tool__selection__exerpt {
	@include leader(math.div($base-rhythm,2));
	@include respond-to($d-vp) {
		max-width: 900px;
	}
}

.tool__selection__exerpt--paired {
	@include respond-to($d-vp) {;
		@include padding-right(300);
		min-height: 100px;
	}
}

.tool__selection__thumb {
	display: none;
	@include respond-to($d-vp) {
		max-width: 200px;
		height: auto;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.tool__selection__image {
	@extend .resource-teaser;
	background-color: $ds-dark-matter;
	overflow: hidden;
	text-align: left;
}

.tool_selection_image_alert {
	display: none;
	@include leader(math.div($internal-spacing,2));
	@include respond-to($c-vp) {
		@include leader($internal-spacing);
	}
}

.tool__selection__image--female {
	background-image: url(../svg/fallbacks/characters/square/female-1.png);
	background-image: url(../svg/characters/square/female-1.svg);
	background-size: 100%;
	background-position: center bottom;
	background-repeat: no-repeat;
}

.tool__selection__image--male {
	@extend .tool__selection__image--female;
	background-image: url(../svg/fallbacks/characters/square/male-1.png);
	background-image: url(../svg/characters/square/male-1.svg);
}

.tool__selection__image--female-2 {
	@extend .tool__selection__image--female;
	background-image: url(../svg/fallbacks/characters/square/female-2.png);
	background-image: url(../svg/characters/square/female-2.svg);
}

.tool__selection__image--male-2 {
	@extend .tool__selection__image--female;
	background-image: url(../svg/fallbacks/characters/square/male-2.png);
	background-image: url(../svg/characters/square/male-2.svg);
}

.tool__selection__image--female-3 {
	@extend .tool__selection__image--female;
	background-image: url(../svg/fallbacks/characters/square/female-3.png);
	background-image: url(../svg/characters/square/female-3.svg);
}

.tool__selection__image--male-3 {
	@extend .tool__selection__image--female;
	background-image: url(../svg/fallbacks/characters/square/male-3.png);
	background-image: url(../svg/characters/square/male-3.svg);
}

.tool__selection__image__title {
	@extend .resource-teaser__title;
}

.tool__selection__image__label {
	@extend .resource-teaser__title;
	z-index: 3;
	top: 0;
	opacity: 0;
}

.tool__selection__image__radio {
	margin: 10px !important;
	.js & {
		visibility: hidden;
	}
}

.tool__selection__image--checked {
	@include vendor(transition, all ease-out 0.15s);
	&:before {
		z-index: 3;
		position: absolute;
		top: 0;
		right: 0;
		@extend .as-svg;
		@extend .svg-icon-tick;
		width: 35px;
		height: 35px;
		background-color: #fff;
		background-size: 22px 22px;
		display: block;
		content: "";
	}
}

.tool__selection__image--deselected {
	@include vendor(filter, grayscale(100%));
	opacity: 0.4;
}

.tool__selection__slider {
	position: relative;
	@include leader($base-rhythm);
	@include padding-all($internal-spacing);
	@include padding-leader(math.div($internal-spacing,1.5));
	@include padding-trailer(math.div($internal-spacing,1.5));
	@extend .fill-dark-matter;
	overflow: hidden;
	border-radius: $border-radius;
}

.tool__selection__slider__label {
	color: #fff;
	font-weight: 300;
	text-align: center;
	@include trailer(40);
}

.tool__selection__slider__controls {
	position: relative;
	@include leader(math.div($base-rhythm,1.5));
	input {
		display: none;
	}
}

.tool__selection__slider__bar {
	height: 10px;
	width: 100%;
	position: relative;
	z-index: 0;
	background-color: #fff;
}

.tool__selection__slider__handle {
	@extend .fill-io;
	@extend .svg-icon-drag;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 20px 43px;
	text-indent: -9999em;
	display: block;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	position: absolute;
	top: -18px;
	left: 50%;
	margin-left: -22px;
	z-index: 3;
}

.rangeslider__handle {
	background-color: $ds-io;
}

.rangeslider__fill,
.rangeslider__fill__bg {
	background-color: #fff;
	border-radius: 0;
}

.tool__selection__slider__option-a {
	position: relative;
	float: left;
	display: inline-block;
	width: 50%;
	text-align: left;
	@include leader(22);
	@include font-size($eta);
	@extend %is-uppercase;
	font-weight: 600;
	color: #fff;
}

.tool__selection__slider__option-b {
	@extend .tool__selection__slider__option-a;
	text-align: right;
}

.tool__selection__slider__option__radio {
	display: inline-block;
	float: left;
	text-align: center;
	.js & {
		display: none;
	}
}


// Result
// ------------------------------------------------------------

.tool__result {
	position: relative;
}

.tool__result__image-mask {
	overflow: hidden;
	position: relative;
	@include respond-to-max($d-vp) {
		height: 220px;
		background-color: $ds-dark-matter;
		@include padding-leader(10);
	}
	@include respond-to($nav-port-change) {
		@include leader(-120);
	}
}

.tool__result__image {
	height: auto;
	margin-left: auto;
	margin-right: auto;
	display: block;
	max-width: 200px;
	display: block;
	position: relative;
	@include respond-to($d-vp) {
		max-width: 180px;
		margin-right: 0;
	}
	@include respond-to($nav-port-change) {
		max-width: 220px;
		html.ie9 &, html.gt-ie9 & {
			width: 100%;
		}
	}
	@include respond-to($max-vp) {
		margin-right: auto;
	}

	// Added to modify the styling of paired results

	&.tool__result__image--pair {
		max-width: 250px;

		@include respond-to($a-vp) {
			max-width: 350px;
		}

		@include respond-to($d-vp) {
			max-width: 300px;
		}

		@include respond-to($nav-port-change) {
			max-width: none;

		}
	}
}

.tool__result__breakdown {
	@include leader(math.div($base-rhythm,2));
	@include respond-to($nav-port-change) {
		max-width: 570px;
	}
	@include respond-to($max-vp) {
		@include leader(math.div($base-rhythm,2));
	}
	.is-typeset {
		p, li {
			@include respond-to($nav-port-change) {
				@include font-size($para + 1);
			}
		}
	}
}

.tool__result__heading {
	@include font-size($beta);
	@include leading($beta);
	@include trailer(math.div($beta,1.5));
	@include padding-trailer(8);
	@include respond-to($nav-port-change) {
		@include font-size($beta + 12);
		@include trailer($beta);
	}
}
