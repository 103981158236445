// ------------------------------------------------------------
// Sharing stuff
// ------------------------------------------------------------

.sharing {
	position: relative;
	@extend %pie-clearfix;
}

.sharing__icons {
	position: relative;
}

.sharing__icon {
	display: inline-block;
	position: relative;
	a {
		@include vendor(transition, top ease-in-out 0.15s);
		position: relative;
		top: 0;
		@include link-active-styles {
			top: -8px;
		}
	}
}