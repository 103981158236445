// ------------------------------------------------------------
// Logos
// ------------------------------------------------------------

.logos-list {
	overflow: hidden;
}

.logos-list--footer {
	@include leader($internal-spacing);
}

.logos-list__item {
	float: left;
	display: inline-block;
	.logos-list--footer & {
		width: 50%;
		@include trailer(30);
		@include respond-to($b-vp) {
			width: 25%;
			margin-bottom: 0;
		}
		@include respond-to($nav-port-change) {
			width: 190px;
			margin-right: 20px;
		}
	}
}

.logos-list__link {
	display: block;
}

.logos-list__image {
	@extend %max-width;
	padding: 0 1.25em;
	@include respond-to($b-vp) {
		padding: 0 0.5em;
	}
	@include respond-to($c-vp) {
		padding: 0 1em;
	}
}