@use 'sass:math';

// ------------------------------------------------------------
// Pagination
// ------------------------------------------------------------

.pagination {
	overflow: hidden;
	@include leader(math.div($internal-spacing,4));
	@include padding-leader(math.div($internal-spacing,4));
	@include respond-to($d-vp) {
		@include leader(math.div($internal-spacing,2));
		@include padding-leader(math.div($internal-spacing,2));
	}
}

.pagination__marker {
	text-align: center;
	width: 20%;
	float: left;
	display: inline-block;
	line-height: 1.4;
	.pagination--buttons & {
		//@include respond-to($d-vp) {
			@include padding-leader(math.div($base-rhythm,2));
		//}
	}
}

.pagination__tool {
	.no-js & {
		display: none;
	}
}

.pagination__tool__nojs {
	.js & { 
		display: none;
	}
}

.pagination__next,
.pagination__finish {
	width: 40%;
	float: left;
	display: inline-block;
	text-align: right;
	line-height: 1.4;
}

.pagination__previous {
	width: 40%;
	float: left;
	display: inline-block;
	line-height: 1.4;
	text-align: left;
}

.pagination__finish {
	display: none;
}