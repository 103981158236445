// ------------------------------------------------------------
// For sizing see _sizing.scss
// ------------------------------------------------------------

// ------------------------------------------------------------
// Background icons
// ------------------------------------------------------------

// Default icon behavior
.ico {
	text-indent: -9999em;
	display: inline-block;
	@extend .background-contain;
	color: $heading-color;
}

// Round corners
.ico--rounded { border-radius: $border-radius; }


// ------------------------------------------------------------
// Inline icons
// ------------------------------------------------------------

.ico-inline {
	display: inline-block;
	vertical-align: middle; 
}