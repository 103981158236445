// ------------------------------------------------------------
// Sections 
// Relates to the spacing of whole sections
// ------------------------------------------------------------

// Padding
.padd-section-top-default 	{ 
	@include padding-leader($internal-spacing); 
	@include respond-to($c-vp) {
		@include padding-leader($internal-spacing*2);
	}
	@include respond-to($max-vp) {
		//@include padding-leader($internal-spacing*2.5);
	}
}

.padd-section-bottom-default { 
	@include padding-trailer($internal-spacing); 
	@include respond-to($c-vp) {
		@include padding-trailer($internal-spacing*2);
	}
	@include respond-to($max-vp) {
		//@include padding-trailer($internal-spacing*2.5);
	}
}

.padd-section-both-default 	{ 
	@extend .padd-section-top-default; 
	@extend .padd-section-bottom-default; 
}


// Spacing
.space-section-top-default 	{ 
	@include leader($internal-spacing); 
	@include respond-to($c-vp) {
		@include leader($internal-spacing*2);
	}
}

.space-section-bottom-default { 
	@include trailer($internal-spacing); 
	@include respond-to($c-vp) {
		@include trailer($internal-spacing*2);
	}
}

.space-section-both-default { 
	@extend .space-section-top-default; 
	@extend .space-section-bottom-default; 
}


// ------------------------------------------------------------
// Modules
// Relates to modules of content that may fall within a section or segment
// ------------------------------------------------------------

// Padding
.padd-module-top-default 		{ @include padding-leader($internal-spacing); }
//.padd-module-left-default 		{ @include padding-left($internal-spacing); }
//.padd-module-right-default 		{ @include padding-right($internal-spacing); }
.padd-module-bottom-default 	{ @include padding-trailer($internal-spacing); }
//.padd-module-both-default 		{ @extend .padd-module-top-default; @extend .padd-module-bottom-default; }
.padd-module-all-default 		{ @include padding-all($internal-spacing); }

// Spacing
.space-module-top-default 		{ @include leader($internal-spacing); }
//.space-module-left-default 		{ @include space-left($internal-spacing); }
//.space-module-right-default 	{ @include space-right($internal-spacing); }
.space-module-bottom-default 	{ @include trailer($internal-spacing); }
//.space-module-both-default 		{ @extend .space-module-top-default; @extend .space-module-bottom-default; }