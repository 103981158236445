@use 'sass:math';

// ------------------------------------------------------------
// Alerts
// ------------------------------------------------------------

.alert {
	@include padding-trailer(math.div($internal-spacing,3));
	@include padding-leader(math.div($internal-spacing,3));
	position: relative;
}

.alert--notice {
	@include padding-left(math.div($internal-spacing,2));
	@include padding-right(math.div($internal-spacing,2));
	color: $heading-color;
	border-left: $default-border-width solid $warning;
	background-color: #fff;
	p {
		padding-left: math.div($internal-spacing-pixel,1.25);
	}
	&:after {
		width: 20px;
		height: 20px;
		top: 50%;
		left: math.div($internal-spacing-pixel,3);
		margin-top: -10px;
		content: "";
		@extend .as-svg;
		display: block;
		position: absolute;
		@extend .svg-icon-alert;
	}
}

.alert--error {
	@extend .alert--notice;
	border-color: $error;
	&:after {
		@extend .svg-icon-error;
	}
}

.alert--success {
	@extend .alert--notice;
	border-color: $success;
	&:after {
		@extend .svg-icon-success;
	}
}
