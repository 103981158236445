@use 'sass:math';

.listing-item {
    @include pie-clearfix;
    position: relative;
    margin: $internal-spacing-pixel 0;
    &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.listing-item__media {
    img {
        max-width: 100%;
    }
    + .listing-item__content {
        @include respond-to($d-vp) {
            padding-left: $internal-spacing-pixel*2;
        }
    }
}

.listing-item__content {

}

.listing-item__heading {
    @include font-size($delta);
    line-height: 1.4;
    margin-bottom: math.div($internal-spacing-pixel,4);
}

.listing-item__subheading {
    @include font-size($zeta);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: math.div($internal-spacing-pixel,2);
}

.listing-item__meta {
    list-style: none;
    @include font-size($zeta);
    margin: math.div($internal-spacing-pixel,1.5) 0 math.div($internal-spacing-pixel,0);
    li {
        display: inline-block;
        margin-right: math.div($internal-spacing-pixel,2);
        svg {
            width: 17px;
            height: 17px;
            vertical-align: text-top;
            margin-right: 10px;
            display: inline-block;
            // fill: $cSmoke;
        }
    }
}

// @use 'sass:math';

// // ------------------------------------------------------------
// // Listings
// // ------------------------------------------------------------

// .listing {
// 	position: relative;
// 	@extend %pie-clearfix;
// }

.listing-meta {
	@include trailer(math.div($base-rhythm,2));
	@include font-size($gamma);
	@include leading($gamma*1.15);
	@include padding-trailer(5);
	span {
		font-weight: 300;
	}
	@include respond-to($d-vp) {
		@include leader(math.div($base-rhythm,3));
		@include trailer($base-rhythm);
	}
}

.listing-sort {
	overflow: hidden;
	@include trailer(math.div($base-rhythm,3));
	span {
		display: block;
		@include respond-to($max-vp) {
			float: right;
			display: inline-block;
		}
	}
	.listing-sort__link {
		text-decoration: underline;
		@include space-right(10);
		&:last-of-type {
			margin-right: 0;
		}
	}
	.listing-sort__link--active {
		color: $action-respond;
	}
}

// .listing-items--locations {
// 	overflow-y: scroll;
// 	overflow-x: hidden;
// 	@include respond-to($d-vp) {
// 		height: $location-map-height;
// 	}
// }

// // Stacked listing
// // ------------------------------------------------------------

// .listing__item-stacked {
// 	background-color: $ds-io;
// 	color: $heading-color;
// 	border-bottom: 5px solid #fff;
// 	position: relative;
// 	overflow: hidden;
// 	min-height: 160px;
// 	&:last-child {
// 		border-bottom: 0;
// 	}
// }

// .listing__item-stacked--location {
// 	min-height: 0;
// }

// .listing__item-stacked__header {
// 	display: block;
// 	background-color: rgba(255,255,255,0.55);
// 	overflow: hidden;
// 	@include padding-all(math.div($internal-spacing,2));
// 	@include padding-leader(math.div($internal-spacing,4));
// 	@include padding-trailer(math.div($internal-spacing,4));
// 	@include trailer(8);
// }

// .listing__item-stacked__district {
// 	@extend %is-uppercase;
// 	@include font-size($delta);
// 	@include respond-to($d-vp) {
// 		width: 50%;
// 		float: left;
// 		display: inline-block;
// 	}
// }

// %listing__item__metadata {
// 	@include font-size($eta + 2);
// 	@include leading($eta*1.5);
// 	@extend %is-uppercase;
// 	display: block;
// }

// .listing__item-stacked__date {
// 	@include font-size($eta);
// 	@include leading($eta*1.5);
// 	@extend %is-uppercase;
// 	font-weight: 600;
// 	display: block;
// 	@include respond-to($d-vp) {
// 		@include leader(10);
// 		width: 50%;
// 		float: left;
// 		display: inline-block;
// 		text-align: right;
// 	}
// }

// .listing__item-stacked__space-center {
// 	@extend %listing__item__metadata;
// 	@include font-size($eta);
// 	font-weight: 600;
// 	@include leader(8);
// }

// .listing__item-stacked__location {
// 	@extend %listing__item__metadata;
// 	font-weight: 400;
// 	text-transform: none;
// 	letter-spacing: normal;
// 	@include font-size($eta + 2);
// 	@include leading($eta*2);
// }

// .listing__item-stacked__wrapper {
// 	@extend %pie-clearfix;
// 	position: relative;
// }

// .listing__item-stacked__body {
// 	@extend .listing__item-stacked__header;
// 	background-color: transparent;
// 	border-bottom: 0;
// 	p {
// 		@include leader(12);
// 		@include leading(math.div($base-rhythm,1.5));
// 		@include respond-to-max($nav-port-change) {
// 			display: none;
// 		}
// 	}
// 	@include respond-to($d-vp) {
// 		width: 55%;
// 	}
// 	@include respond-to($nav-port-change) {
// 		width: 70%;
// 	}
// 	@include respond-to($max-vp) {
// 		width: 80%;
// 	}
// }

// .listing__item-stacked__body-location {
// 	@include padding-all(math.div($internal-spacing,2));
// 	p {
// 		@include leader(6);
// 		@include leading(math.div($base-rhythm,1.5));
// 	}
// }

// .listing__item-stacked__footer {
// 	@include respond-to($d-vp) {
// 		position: absolute;
// 		top: 0;
// 		right: 0;
// 		margin-top: -8px;
// 		background-color: rgba(255,255,255,0.35);
// 		width: 35%;
// 		height: 110%;
// 		min-height: 160px;
// 		font-weight: 600;
// 	}
// 	@include respond-to($nav-port-change) {
// 		width: 28%;
// 	}
// 	@include respond-to($max-vp) {
// 		width: 20%;
// 	}
// }

// .listing__item-stacked__paired-footer {
// 	@extend .listing__item-stacked__body-location;
// 	padding-top: 0;
// 	overflow: hidden;
// }

// .listing__item-stacked__footer__link {
// 	@include padding-leader(math.div($internal-spacing,2));
// 	@include padding-left($internal-spacing);
// 	@include padding-right(math.div($internal-spacing,2));
// 	display: block;
// 	color: $heading-color;
// 	height: 100%;
// 	line-height: 1.2;
// 	font-weight: 600;
// 	.ico {
// 		display: block;
// 		@include trailer(4);
// 		color: $heading-color;
// 	}
// 	@include link-active-styles {
// 		color: $heading-color;
// 		text-decoration: underline;
// 		background-color: rgba(255,255,255,0.3);
// 	}
// 	@include respond-to-max($d-vp) {
// 		background-color: rgba(0,0,0,0.05);
// 		@include padding-all(math.div($internal-spacing,2));
// 		.ico {
// 			float: right;
// 			@include space-left(5);
// 		}
// 	}
// }

// .listing__item-stacked__paired-footer__link {
// 	@include padding-all(math.div($internal-spacing,2));
// 	display: block;
// 	color: $heading-color;
// 	height: 100%;
// 	line-height: 1.2;
// 	font-weight: 600;
// 	background-color: rgba(0,0,0,0.1);
// 	overflow: hidden;
// 	.ico {
// 		float: right;
// 		@include space-left(5);
// 	}
// 	+ .listing__item-stacked__paired-footer__link {
// 		background-color: rgba(0,0,0,0.05);
// 	}
// 	@include link-active-styles {
// 		color: $heading-color;
// 		text-decoration: underline;
// 		background-color: rgba(255,255,255,0.3);
// 	}
// 	@include respond-to($d-vp) {
// 		width: 50%;
// 		float: left;
// 	}
// }

// // Listing tints
// // ------------------------------------------------------------

// @each $number, $color in
// 		('1', lighten($ds-io, 18%)),
// 		('2', lighten($ds-neptune, 32%)),
// 		('3', lighten($ds-venus, 38%)),
// 		('4', lighten($ds-solar, 4%)),
// 		('5', lighten($ds-mars, 28%)),
// 		('6', lighten($ds-vacuum, 44%)),
// 		('7', lighten($ds-io, 28%)),
// 		('8', lighten($ds-neptune, 42%)),
// 		('9', lighten($ds-venus, 28%)),
// 		('10', lighten($ds-solar, 9%)),
// 		('11', lighten($ds-mars, 38%)),
// 		('12', lighten($ds-vacuum, 50%)),
// 		('13', lighten($ds-io, 32%)),
// 		('14', lighten($ds-neptune, 52%)),
// 		('15', lighten($ds-venus, 46%)),
// 		('16', $ds-solar),
// 		('17', lighten($ds-mars, 22%)),
// 		('18', lighten($ds-vacuum, 54%)),
// 		('19', $ds-io),
// 		('20', lighten($ds-neptune, 26%)),

// 	{
// 	.listing--tint#{$number} {
// 		background-color: $color;
// 	}
// }
