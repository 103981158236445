@use 'sass:math';

// ---------------------------------------
// Table styles
// ---------------------------------------

.table-styled {
	width: 100%;
	border-top: $default-border-width solid $default-border-color;
	td {
		position: relative;
		line-height: 1.4;
		vertical-align: top;
	}
	@include respond-to-max($table-respond) {
		border-collapse: separate;
		table, thead, tbody, th, td, tr {
			display: block;
		}
		thead, th {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		tr {
			@include trailer(math.div($internal-spacing,2));
			border-top: $default-border-width solid $default-border-color;
			overflow: hidden;
			&:first-child {
				border-top: none;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		td {
			position: relative;
			background: #fff;
			border-bottom: $default-border-width solid $default-border-color;
			border-right: $default-border-width solid $default-border-color;
			border-left: $default-border-width solid $default-border-color;
			padding-left: 0;
			vertical-align: top;
			@include padding-leader(math.div($internal-spacing,4));
			@include padding-trailer(math.div($internal-spacing,4));
			padding-left: 45%;
			&:before {
				position: relative;
				display: inline-block;
				width: 45%; 
				word-break: break-word;
				content: attr(title);
				@include padding-left(math.div($internal-spacing,2));
				@include padding-right(math.div($internal-spacing,2));
				margin-right: $internal-spacing-percent*1.5;
				color: $heading-color;
				font-weight: 700;
				position: absolute;
				top: math.div($internal-spacing-pixel,4);
				left: 0;
			}
		}
	}	
	@include respond-to($table-respond) {
		border: none;
		th, td {
			border-right: $default-border-width solid $default-border-color;
			&:last-child {
				border-right: none;
			}
		}
		thead {
			th {
				@include padding-all(math.div($internal-spacing,3));
				@include padding-left(math.div($internal-spacing,2));
				@include padding-right(math.div($internal-spacing,2));
			}
		}
		tbody {
			tr {
				background: #fff;
				border-bottom: $default-border-width solid $default-border-color;
				&:nth-child(even) {
					
				}
			}
			td {
				@include padding-all(math.div($internal-spacing,4));
				@include padding-left(math.div($internal-spacing,2));
				@include padding-right(math.div($internal-spacing,2));
			}
		}
	}
}