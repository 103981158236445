// ------------------------------------------------------------
// Media controllers
// ------------------------------------------------------------

// Image control class
.max-width 			{ @extend %max-width; }
picture img 		{ @extend %max-width; }
figure img 			{ @extend %max-width; display: block; }
.is-typeset img		{ @extend %max-width; }

// Basic responsive videos
// http://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
// ------------------------------------------------------------

.media-video-wrapper {
	position: relative;
	@extend .ratio-16-9;
	height: 0;
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.media-wall {
	//background-color: $ds-space-dust;
}

