// ------------------------------------------------------------
// Settings
// ------------------------------------------------------------

@import "color";


// ------------------------------------------------------------
// :: Global variables
// ------------------------------------------------------------

$border-radius						: 6px;		// Default radius
$internal-spacing-percent 			: 3%;		// Used by grid system. Can be used elsewhere
$internal-spacing					: 30; 		// Space internally with fixed measure
$internal-spacing-pixel				: 30px; 	// Space internally with fixed measure in pixels 
$default-border-width 				: 2px; 		// Ruler width

// ------------------------------------------------------------
// :: Set heights
// ------------------------------------------------------------

$hero-full-height					: 600px;	// How high is the hero at full height
$hero-minor-full-height				: 540px;	// How high is the minor hero at full height
$aside-title-frame					: 50px;		// Sidebar title hieght
$character-size						: 60px;		// Square display size of cartoon charater
$location-map-height				: 740px;	// Map containing locations
$profile-photo-size					: 200px;	// Photosize for profiles
$training-item-min-height			: 370px;	// Training grid item

// ------------------------------------------------------------
// :: Breakpoints
// Add and replace as needed
// ------------------------------------------------------------

$a-vp								: 300px;									// first viewport change @
$b-vp 								: 580px;
$c-vp 								: 700px;
$d-vp 								: 890px;
$max-grid 							: 1200px; 									// Max grid width
$max-vp 							: ($max-grid + $internal-spacing-pixel*2); 	// Max viewport width

$ie-fixed-vp						: $d-vp;    								// Viewport for IE fixed width

$start-columns 						: $a-vp;									// Kick columns in above this break (for grid)
$table-respond						: $c-vp;									// Width where tables change layout
$nav-port-change					: 1020px;									// Where we go wholesale from 'mobile' nav, to 'desktop'


// ------------------------------------------------------------
// :: Type
// Greek alphabet naming system. 
// Obscure, yet hierarchical. 
// Allows us to avoid naming based on html object. 
// ------------------------------------------------------------

$alpha   	: 42;
$beta		: 27;
$gamma		: 24;
$delta		: 20;
$epsilon	: 17;
$zeta		: 16;
$eta		: 14;

// Calc units
$rembase 			: 10;			// What do we want our rems to calculate from. 10 is nice and easy
$base-rhythm		: 30;			// Vertical rhythm	

// Set units
$base-font-size		: $epsilon; 	// Page default size
$para				: $epsilon;  	 // Paragraph size
$quote				: $gamma;	 	// Quote size
$form-label-size    : $zeta;		// Form label size
$form-legend-size	: $zeta;		// Form legend size 
