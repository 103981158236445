// ------------------------------------------------------------
// Map
// ------------------------------------------------------------

.map-location {
	@extend .fill-vacuum;
	@include respond-to($d-vp) {
		height: $location-map-height;
	}
}

.gm-style-iw * {
    display: block;
    max-width: 200px;
}
.gm-style-iw h4, .gm-style-iw p {
    margin: 0;
    padding: 0;
}
.gm-style-iw a {
    color: #4272db;
}
