@use 'sass:math';

// ------------------------------------------------------------
// Button styles
// ------------------------------------------------------------

.button {
	background: transparent; // // Reset button and reset elements
	border: none; // Reset button and reset elements
	// ------- //
	display: inline-block;
	text-align: center;
	padding: 18px 22px;
	padding: 1.8rem 2.2rem;
	color: $heading-color;
	font-size: 90%;
	background-color: $ds-io;
	text-decoration: none;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	line-height: 1;
	font-weight: 600;
	@include vendor(transition, all ease-out 0.25s 0.1s);
	@include link-active-styles {
		color: #fff;
		background-color: $action;
	}
}

//.button--var {
	//background-color: lighten($ds-io, 10%);
//}

.button--secondary {
	background-color: $action;
	color: #fff;
	@include link-active-styles {
		color: $heading-color;
		background-color: $ds-io;
	}
}

.button--disabled {
	opacity: 0.5;
	cursor: not-allowed;
	@include vendor(filter, grayscale(100%));
}

.button--icon {
	@include padding-right(60);
	font-size: 100%;
	text-align: left;
	position: relative;
	padding: 14px 60px 13px 22px;
	display: block;
	margin: 1px 1px 0 0;
	@include link-active-styles {
		color: $heading-color;
		background-color: lighten($action, 10%);
	}
	.button__icon {
		display: block;
		@extend .ico; 
		@extend .ico--20;
		@extend .as-svg;
		position: absolute;
		top: 50%;
		margin-top: -10px;
		right: 22px;
	}
}

.button--trigger {
	width: 100%;
	text-align: center;
}

.button--descriptive {
	@include padding-leader(25);
	@include padding-trailer(25);
	@include padding-right(60);
	font-size: 100%;
	text-align: left;
	position: relative;
	background-color: $ds-venus;
	color: #fff;
	display: block;
	&:after {
		display: block;
		@extend .ico; 
		@extend .ico--20;
		@extend .as-svg;
		@extend .svg-icon-plus;
		position: absolute;
		top: 50%;
		margin-top: -10px;
		right: 22px;
		content: "";
		z-index: 90; // safari weirdness fix
		@include vendor(transition, all ease-out 0.15s 0.1s);
	}
	span {
		display: block;
		font-weight: 300;
		color: inherit;
		@include leader(math.div($base-rhythm,4));
		font-size: 90%;
		line-height: 1.2;
	}
	@include link-active-styles {
		background-color: $ds-dark-matter;
		color: #fff;
		@include respond-to($nav-port-change) {
			&:after {
				@include vendor(transition, all ease-out 0.25s 0.1s);
				@include vendor(transform, rotateZ(90deg));
				@include vendor(transform-origin, center center);
			}
		}
	}
}