@use 'sass:math';

// ------------------------------------------------------------
// Aligment and meter
// ------------------------------------------------------------

.max-meter 				{ max-width: 800px; }
.max-meter--center 		{ margin-left: auto; margin-right: auto; }

// ------------------------------------------------------------
// Font features
// ------------------------------------------------------------

.apply-font-features 	{ @include vendor(font-feature-settings, "liga", "dlig"); }

// ------------------------------------------------------------
// Blockquotes
// ------------------------------------------------------------

.as-blockquote {
	@include padding-left($base-font-size);
	@extend .rule;
	@extend .rule--left;
	@extend .rule--io;
	p {
		@include font-size($quote); 
	}
	cite {
		display: block;	
		@include leader($base-font-size);
	}
}

// ------------------------------------------------------------
// Typeset additions
// ------------------------------------------------------------

.is-typeset {
	blockquote {
		display: block;
		@include trailer($base-rhythm);
		@include leader(math.div($base-rhythm,2));
		@extend .as-blockquote;
	}
	figure {
		@include trailer($base-rhythm);
	}
	figcaption {
		position: relative;
		@extend %font-handwritten;
		@include font-size($delta);
		@include padding-left(35);
		@include leading(22);
		@include leader(8);
		&:before {
			display: block;
			content: "";
			width: 20px;
			height: 20px;
			@extend .as-svg;
			@extend .svg-arrow-handrawn;
			position: absolute;
			top: -4px;
			left: 0;
		}
	}
	.heading-largest--gone-rogue {
		@include font-size($beta + 2);
		line-height: 1.2;
	}
}

.is-typeset--upscale-type {
	@include respond-to($d-vp) {
		h1 			{ @include font-size($beta + 6); }
		h2,h3		{ @include font-size($beta); }
		h4,h5, h6	{ @include font-size($gamma); }
		p,li 		{ @include font-size($delta); }
	}
}

.is-typeset--tighten-leading-ss {
	@include respond-to-max($b-vp) {
		p, li {
			line-height: 1.4;
		}
	}
}