// Global reset
// -----------------------------------------------------------

*, *:before, *:after { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font: inherit; font-size: 100%; vertical-align: baseline; }
table { border-collapse: collapse; border-spacing: 0; }
caption, th, td { text-align: left; font-weight: normal; vertical-align: middle; }
q, blockquote { quotes: none; }
q:before, q:after, blockquote:before, blockquote:after { content: ""; content: none; }
a img { border: none; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary { display: block; }


// Forms. If no forms, remove these bad boys
// -----------------------------------------------------------

button, input { line-height: normal; }
button, input, select, textarea { font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; }
button, input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; *overflow: visible; }
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }
textarea { overflow: auto; vertical-align: top; resize: vertical; }