// ------------------------------------------------------------
// Colours
// ------------------------------------------------------------

// Brand colours
// https://bitbucket.org/fffunction/wiki/wiki/Design%20styles
// ------------------------------------------------------------

// Colours
$ds-io								: #3cd52e;
$ds-venus							: #cd0da0; // Wave compliant // Original --> #f128c1;
$ds-neptune							: #007eac; // Wave compliant // Original --> #0097cd;
$ds-solar							: #efeb56;
$ds-mars							: #d32f53;

// Greyscales
$ds-dark-matter						: #231c34;
$ds-vacuum							: #524c62;
$ds-vacuum-lighter					: lighten($ds-vacuum, 25%);
$ds-space-dust						: #f0edf7;


// Social shit (as needed)
// ------------------------------------------------------------

$facebook-color 					: #3B5998;
$twitter-color 						: #4099FF;
$linkedin-color						: #007bb6;
$youtube-color						: #e52d27;
$pinterest-color					: #C92228;
$instagram-color 					: #517fa4;
$tumblr-color 						: #32506d;
$foursquare-color 					: #0072b1;
$google-color 						: #dd4b39;
$flickr-color 						: #ff0084;


// Colors assigned to roles
// Replace assignments with brand colours
// ------------------------------------------------------------

$body-color 						: $ds-space-dust;  			// Color of the body, or page bkg
$base-color							: $ds-vacuum;  				// Base color. Our root. I use this for default text color
$heading-color						: $ds-dark-matter;  		// Color of headings
$action								: $ds-neptune;				// Better way to say link color
$action-respond						: $ds-venus;				// Secondary links
$default-border-color 				: $ds-vacuum-lighter;		// Borders

$form-label-color					: $heading-color;			// Labels
$form-legend-color					: $heading-color;			// Legends
$form-placeholder-color				: $heading-color;			// Labels
$form-checkbox-color				: $ds-io;					// Checkbox ticks
$form-radio-color					: $ds-io;					// Radio

$warning 							: $ds-solar;				// Careful now
$error 								: $ds-mars;					// Down with that sort of thing
$success 							: $ds-io;					// Good bunch of lads