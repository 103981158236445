@include grid;

// ------------------------------------------------------------

.row {
	@extend %pie-clearfix;
	min-width: auto; 
	position: relative;
	margin-left: auto;
	margin-right: auto;
	padding-left: $internal-spacing-percent;
	padding-right: $internal-spacing-percent;
	//@include grid_columns(4);
	@include respond-to($b-vp) {
		@include grid_columns(2);
	}
	@include respond-to($c-vp) {
		@include grid_columns(4);
		@include grid_columns(6);
	}
	@include respond-to($d-vp) {
		@include grid_columns(10);
		@include grid_columns(12);
	}
	@include respond-to($max-vp) {
		max-width: $max-grid;
		@include grid_columns(15);
		@include grid_columns(16);
		padding-left: 0;
		padding-right: 0;
	}
}

.row--internal {
	padding-left: 0;
	padding-right: 0;
}

// Drag row left to allow for equal spaced grid. AKA gutters
// Gutters set in mixins.scss file
.row__colspaced {
	position: relative;
	@extend %pie-clearfix;
	@include respond-to($start-columns) {			
		min-width: (100% + $internal-spacing-percent); 	
		left: -$internal-spacing-percent; 	
	}
}

.colclear-2-6 {
	@include respond-to($b-vp) {
		&:nth-child(odd) {
			clear: left;
		}
	}
	@include respond-to($c-vp) {
		&:nth-child(odd) {
			clear: none;
		}
		&:nth-child(3n+1) {
			clear: left;
			// opacity: 0.3; // debug
		}
	}
}

.colclear-6 {
	@include respond-to($c-vp) {
		&:nth-child(3n+1) {
			clear: left;
			//opacity: 0.3; // debug
		}
	}
}

.colclear-4-12 {
	@include respond-to($c-vp) {
		&:nth-child(odd) {
			clear: left;
		}
	}
	@include respond-to($max-vp) {
		&:nth-child(odd) {
			clear: none;
		}
		&:nth-child(3n+1) {
			clear: left;
			//opacity: 0.3; // debug
		}
	}
}