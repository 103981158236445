@use 'sass:math';

// ------------------------------------------------------------
// Header
// ------------------------------------------------------------

.header {
	z-index: 2;
	position: relative;
	overflow: hidden;
	background-color: $ds-dark-matter;
	@include trailer(math.div($internal-spacing,2));
	@include respond-to($d-vp) {
		margin-bottom: 0;
	}
	@include respond-to($nav-port-change) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin-bottom: 0;
		margin-top: 0;
		overflow: visible;
		background: transparent;
	}
	.svg-logo-primary {
		z-index: 3;
		text-indent: -9999em; 
		display: inline-block;
		width: 140px; 
		height: 54px; 
		@include leader(math.div($internal-spacing,2));
		@include trailer(math.div($internal-spacing,1.75));
		@media 	only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   min--moz-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   -o-min-device-pixel-ratio: 2/1) and (min-width: $d-vp),
			only screen and (   min-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   min-resolution: 192dpi) and (min-width: $d-vp),
			only screen and (   min-resolution: 2dppx) and (min-width: $d-vp) {
				-webkit-backface-visibility: hidden;
				@include vendor(transition, all ease-out 0.15s 0.1s);
				@include vendor(transform-origin, center center);
				@include vendor(filter, drop-shadow(3px 3px 3px rgba(0,0,0,0.4)));
		}
		// @include respond-to($nav-port-change) {
		// 	width: 180px;
		// 	height: 70px;
		// 	position: absolute;
		// 	left: 50%;
		// 	top: $internal-spacing-pixel;
		// 	margin-left: -(180px*0.41666667); // Requires jiggling
		// 	margin-top: 0;
		// 	margin-bottom: 0;
		// 	@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
		// 		@include link-active-styles {
		// 			@include vendor(transform, scale3d(1.11,1.11,1.1));
		// 		}
		// 	}
		// }
		@include respond-to($nav-port-change) {
			width: 228px;
			height: 88px;
			top: $internal-spacing-pixel;
			// margin-left: -(228px*0.45454545); // Requires jiggling
			@include leader($internal-spacing);
		}
	}
}

// Search
// ------------------------------------------------------------

.header-search {
	@include pie-clearfix;
	display: none;
	@include respond-to($nav-port-change) {
		position: absolute;
		top: 20px;
		right: $internal-spacing-percent;
		@include font-size($zeta);
		display: inline-block;
		width: 170px;
		z-index: 99;
		input {
			float: left;
			display: inline-block;
			width: 100%;
			border: 0;
			outline: none;
			background-color: #fff;
			padding: 0.3em 1em;
			line-height: 1;
			border-radius: 1.5em;
		}
		button {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			width: 30px;
			height: 30px;
			text-indent: -9999px;
			border-radius: 0 1.5em 1.5em 0;
			background-color: #fff;
			span {
                width: 18px;
                height: 18px;
                background-image: url(../icons/source/search.svg);
				background-size: contain;
				display: block;
				padding: 2px 0 0 2px;
            }
		}
	}
	@include respond-to($max-vp) {
		right: 0;
	}
}

.header-search__label {
	@include a11yhide;
}
