@use 'sass:math';

// ------------------------------------------------------------
// Mixins
// Just a few of our favourite things
// ------------------------------------------------------------


// List styles
// -------------------------------------------------------

// Mixins for list style types
@mixin unordered {
	 list-style-position: outside;
	 list-style-type: disc;
}

@mixin unordered-inside {
	 list-style-position: inside;
	 list-style-type: disc;
}

@mixin ordered {
	list-style-position: outside;
	list-style-type: decimal;
}

@mixin ordered-inside {
	list-style-position: inside;
	list-style-type: decimal;
}

@mixin nobullet {
	list-style-type: none;
}


// REM typesizing
// -------------------------------------------------------

@mixin font-size($size) 	{ font-size: math.div($size, $rembase) + rem; }
@mixin leading($size) 		{ line-height: math.div($base-rhythm, $size); }


// REM spacing
// -------------------------------------------------------

@mixin leader($size) 			{ margin-top: (math.div($size, $rembase) + rem); }
@mixin trailer($size) 			{ margin-bottom: (math.div($size, $rembase) + rem); }
@mixin space-left($size) 		{ margin-left: (math.div($size, $rembase) + rem); }
@mixin space-right($size) 		{ margin-right: (math.div($size, $rembase) + rem); }
@mixin padding-leader($size) 	{ padding-top: (math.div($size, $rembase) + rem); }
@mixin padding-trailer($size) 	{ padding-bottom: (math.div($size, $rembase) + rem); }
@mixin padding-left($size) 		{ padding-left: (math.div($size, $rembase) + rem); }
@mixin padding-right($size) 	{ padding-right: (math.div($size, $rembase) + rem); }
@mixin padding-all($size) 		{ padding: (math.div($size, $rembase) + rem); }


// Lazy font-face generator
// https://gist.github.com/iamkeir/30ec9b01d71d5fe49c81
// -------------------------------------------------------

@mixin font-face($label,$font,$font_path:"../fonts/" ) {
  @font-face {
    font-family: $label;
    src: url('#{$font_path}#{$font}.eot');
    src: url('#{$font_path}#{$font}.eot?#iefix') format('embedded-opentype'),
         url('#{$font_path}#{$font}.woff') format('woff'),
         url('#{$font_path}#{$font}.ttf') format('truetype'),
         url('#{$font_path}#{$font}##{$label}') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}


// Vendor prefixes
// -------------------------------------------------------

@mixin vendor($property, $value...){
	-webkit-#{$property}:$value;
	-moz-#{$property}:$value;
	-ms-#{$property}:$value;
	-o-#{$property}:$value;
	#{$property}:$value;
}


// Link hover styles
// -------------------------------------------------------

@mixin link-active-styles {
	&:hover, &:focus, &:active {
		@content;
	}
}


// Align things vertically
// (http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/)
// -------------------------------------------------------

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


// Ratios
// https://gist.github.com/brianmcallister/2932463
// -------------------------------------------------------

@mixin maintain-ratio($ratio: 1 1) {
	//@if length($ratio) < 2 or length($ratio) > 2 { @warn "$ratio must be a list with two values."; }
	$ratio-width: 100%;
	$ratio-height: percentage(math.div(nth($ratio, 2) , nth($ratio, 1)));
	width: $ratio-width;
	height: 0;
	padding-bottom: $ratio-height;
}


// Media queries with fallbacks if needed
// -------------------------------------------------------

// Reference widths from settings file
@mixin respond-to($media-min) {
	@media screen and (min-width: $media-min) {
	    @content;
	}
}

@mixin respond-to-max($media-max) {
	@media screen and (max-width: $media-max - 1px) {
	    @content;
	}
}


@mixin respond-to-min-max($media-min, $media-max) {
	 @media screen and (min-width: $media-min) and (max-width: $media-max - 1px) {
	    @content;
	}
}


// Retina images
// -------------------------------------------------------

// Usage: Send path, image name, and width and height.
// Name retina images [name]2x.

@mixin image-2x($path, $image, $width, $height) {
  background-image: url(#{$path}#{$image});
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url(#{$path}2x/#{$image});
    background-size: $width $height;
  }
}


// HEX to RGB
// -------------------------------------------------------

// http://codepen.io/sturobson/pen/hdKyv
@mixin rgba($color, $value) {
	background-color: $color;
	background-color: rgba($color, $value);
}


// Gradients
// -------------------------------------------------------

@mixin gradient($from, $to, $angle) {
	background-color: $from; // Fallback
	@include vendor (background-image, linear-gradient($angle, $from, $to));
	background-image: linear-gradient($angle, $from, $to);
}


// Swatches (used in fffunction component guide, but left here for reference
// -------------------------------------------------------

@mixin swatch_ref ($content) {
	.swatch {
		background: $content;
	}
	.swatch-color {
		&:after {
			content: "#{$content}";
		}
	}
}



// Grid
// -------------------------------------------------------

// Generate columns names span-#
// Called by: @include grid_columns(#) where # is the amount of columns needed
// Perfect for use in breakpoints to use different columns

@mixin grid_columns($columns){
	@for $i from 1 through $columns{
		.colspan#{$columns}-#{$i}{
			width: math.div(100%,$columns)*$i;
		}
	}
	@for $i from 1 through $columns{
		.push#{$columns}-#{$i}{
			margin-left: math.div(100%,$columns)*$i;
		}
	}

	// deleted commented out code here because it was
	// breaking my node-sass

	.inline-show-at#{$columns} {
		display: inline;
		display: inline-block;
	}
	//.with-trailer#{$columns}{
		//@include trailer($internal-spacing);
	//}
	//.with-leader#{$columns}{
		//@include leader($internal-spacing);
	//}
	.leader-disable#{$columns} {
		margin-top: 0;
	}
	.trailer-disable#{$columns} {
		margin-bottom: 0;
	}
}

@mixin grid {
	// Widths
	*[class*="colspan"] {
		width: 100%;
		display: block;
	}
	// Floats
	*[class*="as-grid"] {
		float: left;
		display: inline;
		display: inline-block;
	}
	// Floats
	*[class*="as-grid-reverse"] {
		float: right;
		display: inline;
		display: inline-block;
	}
	// Gutters
	*[class*="with-gutter"] {
		@include respond-to($start-columns) {
			padding-left: $internal-spacing-percent;
		}
	}
	.hide-at-root {
		display: none;
	}
	.with-trailer {
		@include trailer($internal-spacing);
	}
	.with-leader {
		@include leader($internal-spacing);
	}
}

// A11y hidden
// ------------------------------------------------------------------

@mixin a11yhide {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0px;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}

// Helpers
// ------------------------------------------------------------------

// Oldschool clearfixing
@mixin pie-clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
    //*zoom: 1;
	}
}