@use 'sass:math';

// ------------------------------------------------------------
// Footer
// ------------------------------------------------------------

.footer {
	color: $ds-space-dust;
	li, p {
		font-size: 95%;
		line-height: 1.4;
		@include trailer(math.div($base-rhythm,4));
	}
	a {
		color: #fff;
		text-decoration: underline;
		@include link-active-styles {
			color: #fff;
			text-decoration: none;
		}
	}
	li .ico {
		@include space-right(8);
		vertical-align: middle;
		position: relative;
		top: -2px;
	}
	.button {
		@include padding-leader(12);
		@include padding-trailer(12);
		@include leader(math.div($base-rhythm,2));
		text-decoration: none;
		background-color: $ds-venus;
		@include link-active-styles {
			background-color: $ds-neptune;
		}
	}
	.row + .row {
		@include leader($internal-spacing);
	}
}

.footer__heading {
	@include trailer(math.div($base-rhythm,2));
}

.footer__legal {
	margin-bottom: 0;
	@include leader(math.div($base-rhythm,2));
}
