@use 'sass:math';

// ------------------------------------------------------------
// Lists
// ------------------------------------------------------------

.list-items {
	position: relative;
}

.list__item {
	position: relative;
	@include leading(math.div($base-rhythm,1.25));
	@include leader(math.div($base-rhythm,2));
	&:first-of-type {
		margin-top: 0;
	}
	.aside-m & {
		@include font-size($zeta);
	}
}

.list__item--current {
	> .list__item__link {
		font-weight: 600;
	}
	&.list__item--with-mascot {
		> .list__item__link {
			color: $heading-color;
		}
	}
}

.list__item--divide {
	@include padding-trailer(math.div($base-rhythm,3));
	border-bottom: $default-border-width solid $ds-space-dust;
	@include leader(math.div($base-rhythm,4));
	&:last-of-type {
		border-bottom: 0;
		padding-bottom: 0;
		//.list__item__icon {
			//margin-top: -10px;
		//}
	}
}

.list__item--with-icon {
	@extend .list__item--divide;
	@include padding-right(30);
}

.list__item--with-mascot {
	@extend .list__item--divide;
	overflow: hidden;
	padding-bottom: 0;
}

.list__item__link {
	display: block;
	@include respond-to-max($d-vp) {
		@include padding-leader(6);
		@include padding-trailer(6);
	}
	.list__item--with-mascot & {
		@include padding-leader(10);
		@include padding-trailer(12);
	}
}

.list__item__icon {
	@extend .ico;
	@extend .ico--20;
	position: absolute;
	//top: 50%;
	//margin-top: -16px;
	top: 0;
	right: 0;
}

.list__item__mascot {
	width: 40px;
	height: 40px;
	position: relative;
	display: inline-block;
	float: left;
	@include leader(-10);
	@include space-right(10);
}

.list__item__children {
	border-top: $default-border-width solid $ds-space-dust;
	@include leader(math.div($base-rhythm,4));
}

.list__item__child {
	@include space-left(math.div($internal-spacing,2));
	@include leader(math.div($base-rhythm,6));
	@include padding-trailer(math.div($base-rhythm,4));
	.list__item--with-icon & {
		padding-right: 0;
	}
}