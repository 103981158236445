@use 'sass:math';

// ------------------------------------------------------------
// Specific sizes
// Size icons and images
// ------------------------------------------------------------

$scales: 14, 20, 30, 40, 64;

@each $scale in $scales {
    .ico--#{$scale} {
    	width: ($scale) + px;
    	height: ($scale) + px;
    }
    .ico--padded-#{$scale} {
    	padding: (math.div($scale,3.5)) + px;
    	// Use modernizer to check if has SVG support
	    html.svg & {
	    	border: (math.div($scale,3.5)) + px solid transparent; // Faux padding effect
	    	padding: 0;
	    }
    }
}