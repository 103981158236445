@use 'sass:math';

// ------------------------------------------------------------
// Sections
// ------------------------------------------------------------

.section {
	position: relative;
	z-index: 3;
	@extend %pie-clearfix;
}

// Drag the first section up over the hero
.section--first  {
	@include trailer($internal-spacing);
	@include respond-to($d-vp) {
		position: relative;
		margin-top: -1 * math.div($hero-minor-full-height,2);
		@include respond-to($d-vp) {
			margin-bottom: math.div($character-size,2);
		}
	}
	@include respond-to($nav-port-change) {
		position: relative;
		margin-top: -1 * math.div($hero-minor-full-height,3);
	}
}

// If it's a single column drag, adjust at heigher breakpoints to make the heading neater
.section--first-solo {
	@extend .section--first;
	@include respond-to($d-vp) {
		@include trailer($internal-spacing*2);
	}
	@include respond-to($nav-port-change) {
		margin-top: -1 * math.div($hero-minor-full-height,2.5);
	}
}

.section--first-solo--extend {
	@include respond-to($d-vp) {
		margin-top: -1 * math.div($hero-minor-full-height,1.5);
	}
	@include respond-to($nav-port-change) {
		margin-top: -1 * math.div($hero-minor-full-height,1.95);
	}
}

.section--first-home {
	@extend .section--first-solo;
	@include respond-to($d-vp) {
		margin-top: 0;
	}
	@include respond-to($nav-port-change) {
		margin-top: -1 * math.div($hero-full-height,8);
	}
}

.section--joined__character {
	display: none;
	@include respond-to($d-vp) {
		display: block;
		width: $character-size - 5;
		height: $character-size - 5;
		position: absolute;
		top: -$character-size + 5;
		left: auto;
		right: $internal-spacing-percent;
		margin-left: auto;
	}
	@include respond-to($max-vp) {
		left: auto;
		right: 50%;
		margin-left: auto;
		margin-right: -1($max-vp*.5) + math.div($character-size,2);
	}
}

.section--joined:nth-of-type(odd) {
	.section--joined__character {
		@include respond-to($d-vp) {
			right: auto;
			left: $internal-spacing-percent;
		}
		@include respond-to($max-vp) {
			left: 50%;
			right: auto;
			margin-left: -($max-vp*.5) + math.div($character-size,2);
			margin-right: auto;
		}
	}
}