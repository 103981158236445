@use 'sass:math';

// ------------------------------------------------------------
// Training
// ------------------------------------------------------------

#training {
	counter-reset: missons;
	overflow: hidden;
}

.training__item-frame {
	@extend .mason__item-frame;
	counter-increment: missions;
}

// Standard item
.training__item {
	background-color: $ds-space-dust;
	position: relative;
	overflow: hidden;
	display: block;
	z-index: 2;
	border: 5px solid #fff;
	&:before {
		content: counter(missions, decimal-leading-zero);
		position: absolute;
		z-index: 4;
		top: 5px;
		left: 10px;
		color: #fff;
		display: block;
		font-weight: 600;
	}
	@include respond-to($d-vp) {
		min-height: $training-item-min-height;
	}
}

.training__item--focussed {
	background-color: $ds-vacuum;
	@include respond-to($d-vp) {
		min-height: $training-item-min-height*2;
	}
}

.training__item--blank {
	opacity: 0.5;
}

.training__item__image {
	@extend .background-cover;
	@extend .background-anchor-center-top;
	height: 150px;
	@include vendor(transition, transform ease-in-out 1s);
	img { 
		display: none; 
	}
	.training__item--focussed & {
		height: 200px;
		@include respond-to($b-vp) {
			height: 300px;
		}
	}
}

.training__item__placeholder {
	@extend .training__item__image;
	@extend .fill-vacuum;
}

.training__item__image-link {
	display: block;
	@include respond-to($d-vp) {
		&:hover {
			.training__item__image {
				@include vendor(transform, scale(1.1));
			}			
		}
	}
}

.training__item__header {
	position: relative;
	background-color: $ds-dark-matter;
	@include padding-all(math.div($internal-spacing,2));
	@include padding-leader(math.div($internal-spacing,4));
	@include padding-trailer(math.div($internal-spacing,4));
	@include respond-to($d-vp) {
		@include padding-all(math.div($internal-spacing,1.5));
		@include padding-leader(math.div($internal-spacing,3));
		@include padding-trailer(math.div($internal-spacing,3));
	}
	a {
		color: $ds-io;
		@include link-active-styles {
			color: #fff;
		}
	}
	&:after {
		display: block;
		width: 20px;
		height: 20px;
		content: "";
		@include vendor(transform, rotate3d(0,0,1,45deg));
		@include vendor(transform-origin, 0px 0px);
		position: absolute;
		bottom: 0;
		right: 3px;
		z-index: 2;
		background-color: $ds-dark-matter;
	}
}

.training__item__content {
	@extend .training__item__header;
	background-color: $ds-space-dust;
	@include padding-leader(math.div($internal-spacing,2));
	@include padding-trailer(math.div($internal-spacing,2));
	@include respond-to($d-vp) {
		@include padding-leader(math.div($internal-spacing,2));
	}
	&:after {
		display: none;
	}
	p {
		padding-right: 3%;
	}
	.training__item--focussed & {
		background-color: $ds-vacuum;
		p {
			color: #fff;
		}
	}
}

.training__item__content__excerpt {
	@include font-size($zeta);
	@include leading($zeta*1.3);
}

.training__item__heading {
	@extend .as-heading-large;
	position: relative;
	z-index: 3;
	.training__item--focussed & {
		@include font-size($beta); 
		@include leading($base-font-size*1.5);
	}
}

.training__item__footer {
	@extend .training__item__content;
	html.js & {
		@include respond-to($d-vp) {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
		}
	}
	.button {
		color: $heading-color;
		width: 100%;
	}
}