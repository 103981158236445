// ---------------------------------------
// Gets svgs and provide fallbacks
// ---------------------------------------

// Name SVG's, with the exact filename in an array
$svgs: menu, logo-primary, rocket1, icon-package, icon-drag, icon-tick, icon-external, icon-external-dark, icon-photo, icon-doc, icon-pdf, icon-ppt, icon-file, icon-alert, icon-error, icon-success, icon-video, icon-plus, icon-plus-alt, twitter-white, facebook-white, instagram-white, pinterest-white, arrow-handrawn;


// Get svgs
// ---------------------------------------

@each $icon in $svgs {
    .svg-#{$icon} {
    	background-image: url("../svg/fallbacks/#{$icon}.png");
    }
    // Use modernizer to check if has SVG support
    html.svg {
    	.svg-#{$icon} {
    		background-image: url("../svg/#{$icon}.svg");
    	}
    }
}


// Setup defaults
// ---------------------------------------

.as-svg 		{ @extend .background-cover; color: $heading-color; }
