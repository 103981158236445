@use 'sass:math';

// ------------------------------------------------------------
// Resources
// ------------------------------------------------------------

// Single
// ------------------------------------------------------------

.resource-single__highlight {
	@include leader(math.div($base-rhythm,1.5));
	overflow: hidden;
	a {
		text-decoration: underline;
		color: $ds-io;
		@include link-active-styles {
			color: $ds-space-dust;
		}
	}
	pre {
		overflow-x: scroll;
		background-color: #fff;
		color: $ds-neptune;
	}
}

.resource-single__highlight__spec-list {
	@include leader(math.div($base-rhythm,1.5));
	li + li {
		@include leader(math.div($base-rhythm,2));
	}
	ul > li {
		@include leader(math.div($base-rhythm,4));
		@include respond-to($c-vp) {
			display: inline-block;
			@include space-right(6);
			margin-top: 0;
		}
	}
}


// Teasers
// ------------------------------------------------------------

.resource-teaser {
	background-color: $ds-io;
	position: relative;
	z-index: 1;
	display: block;
	overflow: hidden;
	cursor: pointer;
	@extend .background-cover;
	@include vendor(transition, outline ease-in-out 0.25s);
	@extend .ratio-4-3;
}

a.resource-teaser {
	outline: 3px solid transparent;
	@include link-active-styles {
		outline-color: $ds-io;
	}
}

.resource-teaser__title {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	color: #fff;
	font-weight: 600;
	@include font-size($zeta);
	line-height: 1.2;
	@include padding-all(math.div($internal-spacing,2));
	@include padding-leader(math.div($internal-spacing,4));
	@include padding-trailer(math.div($internal-spacing,4));
	@extend .rule;
	@extend .rule--overline;
	@extend .rule--white;
	@include rgba($ds-dark-matter, 0.5);
}

.resource-teaser__icon {
	position: absolute;
	width: 35px;
	height: 35px;
	top: 0;
	right: 0;
	z-index: 3;
	display: block;
	background-color: #fff;
	border: 8px solid #fff;
}


// Resource item - new
.resource-item{
	background-color: $ds-space-dust;
	position: relative;
	overflow: hidden;
	display: block;
	z-index: 2;
	@include respond-to($d-vp) {
		min-height: $training-item-min-height;
	}
}

.resource-item__image-link {
	display: block;
	@include respond-to($d-vp) {
		&:hover {
			.resource-item__image {
				@include vendor(transform, scale(1.1));
			}
		}
	}
}

.resource-item__image {
	@extend .background-cover;
	@extend .background-anchor-center-top;
	height: 150px;
	@include vendor(transition, transform ease-in-out 1s);
	img {
		display: none;
	}
}

.resource-item__heading {
	position: relative;
	background-color: $ds-dark-matter;
	a {
		color: $ds-io;
		display: block;
		@include padding-all(math.div($internal-spacing,2));
		@include padding-leader(math.div($internal-spacing,4));
		@include padding-trailer(math.div($internal-spacing,4));
		@include respond-to($d-vp) {
			@include padding-all(math.div($internal-spacing,1.5));
			@include padding-leader(math.div($internal-spacing,3));
			@include padding-trailer(math.div($internal-spacing,3));
		}
		@include link-active-styles {
			color: #fff;
		}
	}
	&:after {
		display: block;
		width: 20px;
		height: 20px;
		content: "";
		@include vendor(transform, rotate3d(0,0,1,45deg));
		@include vendor(transform-origin, 0px 0px);
		position: absolute;
		bottom: 0;
		right: 3px;
		z-index: 2;
		background-color: $ds-dark-matter;
	}
}

.resource-item__content {
	background-color: $ds-space-dust;
	padding: math.div($internal-spacing-pixel,2);
	@include respond-to($d-vp) {
		padding: math.div($internal-spacing-pixel,1.5);
	}
	@include font-size($zeta);
	&:after {
		display: none;
	}
	p {
		padding-right: 3%;
	}
	.training__item--focussed & {
		background-color: $ds-vacuum;
		p {
			color: #fff;
		}
	}
}