@use 'sass:math';

// ------------------------------------------------------------
// Heroes
// ------------------------------------------------------------

.aside {
	position: relative;
}

.aside__character {
	max-width: $character-size*2 + 50px;
	height: auto;
	display: block;
	position: absolute;
	top: -$character-size - 25px;
	left: 0;
	z-index: -1;
	@media 	only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   min--moz-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   -o-min-device-pixel-ratio: 2/1) and (min-width: $d-vp),
			only screen and (   min-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   min-resolution: 192dpi) and (min-width: $d-vp),
			only screen and (   min-resolution: 2dppx) and (min-width: $d-vp) {
			html.csstransforms3d & {
				@include vendor(transform, rotateZ(-4deg));
				top: -$character-size - 20px;
			}
		}
	@include respond-to-max($d-vp) {
		display: none;
	}
}

.aside__character--square {
	max-width: $character-size*2;
	top: -$character-size*2;
	html.csstransforms3d & {
		top: -$character-size*1.8;
	}
}

// First section drag up
.aside--first {
	@include respond-to($d-vp) {
		margin-top: $character-size;
	}
	@include respond-to($nav-port-change) {
		margin-top: (math.div($hero-minor-full-height,3)) - $aside-title-frame;
		margin-bottom: math.div(((math.div($hero-minor-full-height,3)) - $aside-title-frame),2);
	}
}

// Modular pieces
.aside-m {
	position: relative;
	+ .aside-m {
		@include leader(math.div($base-rhythm,2));
	}
}

.aside-m__titlebar {
	position: relative;
	z-index: 3;
	@include padding-leader(math.div($internal-spacing,4));
	@include padding-trailer(math.div($internal-spacing,3));
	@include padding-left(math.div($internal-spacing,2));
	@include padding-right(math.div($internal-spacing,2));
	&:after {
		display: block;
		width: 20px;
		height: 20px;
		content: "";
		@include vendor(transform, rotate3d(0,0,1,45deg));
		@include vendor(transform-origin, 0px 0px);
		position: absolute;
		bottom: 0;
		right: 3px;
		z-index: 3;
	}
	@include respond-to($d-vp) {
		min-height: $aside-title-frame;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.aside-m__titlebar--flex {
	@include respond-to($d-vp) {
		@include padding-trailer(math.div($internal-spacing,2));
	}
}

.aside-m__titlebar__title {
	@extend %is-uppercase;
	@include font-size($zeta);
	@include respond-to($d-vp) {
		padding-top: (math.div($aside-title-frame,2)) - math.div($zeta,2);
		line-height: 1;
	}
	a {
		color: white;
		@include link-active-styles {
			color: $ds-dark-matter;
		}
	}
}

.aside-m__body {
	position: relative;
	z-index: 2;
	@include padding-leader(math.div($internal-spacing,2));
	@include padding-trailer(math.div($internal-spacing,3));
	@include padding-left(math.div($internal-spacing,2));
	@include padding-right(math.div($internal-spacing,2));
	@include respond-to($d-vp) {
		@include padding-all(math.div($internal-spacing,2));
	}
	.is-typeset {
		p {
			line-height: 1.4;
		}
	}
}
