// ---------------------------------------
// Masonry controls
// ---------------------------------------

html {
	overflow-y: scroll; // Helps with Masonry's calculations when scrollbars are present
}

%mason__width {
	html.js & { float: none; }
	float: left;
	width: 100%;
	@include respond-to($b-vp) {
		width: 50%;
	}
	@include respond-to($nav-port-change) {
		width: 33.3333333%;
	}
}

.mason__sizer {
	@extend %mason__width;
	display: none;
}

.mason__item-frame {
	@extend %mason__width;
	overflow: hidden;
	position: relative;
	counter-increment: missions;
}

.mason__item--image {
	background-color: #fff;
	@extend .background-cover;
	@extend .background-anchor-center-top;
	 @include maintain-ratio(16 12);
	html.js & { img { display: none; }}
}

.mason__item--video {
	background-color: #000;
}

.mason__item--link {
	@extend .button;
	font-weight: 600;
	color: $heading-color;
	display: block;
	height: 100px;
	padding-top: 40px;
	@include respond-to($nav-port-change) {
		height: 225px;
		padding-top: 108px;
	}
}

.mason__item__block-link {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	text-indent: -999em;
	@include rgba($ds-io, 0.0);
	color: $heading-color;
	font-weight: 600;
	@include padding-leader(8);
	@include padding-left(15);
	@include vendor(transition, all ease-in-out 0.25s);
	@include link-active-styles {
		text-indent: 0;
		color: $heading-color;
		@include rgba($ds-io, 0.7);
	}
	.mason__item--video & {
		background-color: $ds-dark-matter;
	}
}