@use 'sass:math';

// ------------------------------------------------------------
// Main area
// ------------------------------------------------------------

.main {
	position: relative;
	z-index: 4;
	@extend %pie-clearfix;
	@include trailer(math.div($internal-spacing,2));
	@include respond-to($d-vp) {
		margin-bottom: $character-size;
	}
}

.main--first  {
	@include respond-to($d-vp) {
		position: relative;
		margin-bottom: math.div($character-size,2);
		margin-top: -$aside-title-frame*1.45; // Rounding is sometimes a little off
	}
}

.main__heading {
	// text-shadow: 0px 3px 3px rgba(0,0,0,0.3);
	// @include respond-to-max($d-vp) {
		background-color: $ds-neptune;
		@include padding-all(math.div($internal-spacing,2));
		// text-shadow: none;
	// }
}

.main__heading--secondary {
	text-shadow: 0px 3px 3px rgba(0,0,0,0.3);
	@include respond-to-max($d-vp) {
		background-color: darken($ds-neptune, 3%);
		@include padding-leader(math.div($internal-spacing,3));
		@include padding-trailer(math.div($internal-spacing,3));
		text-shadow: none;
	}
}

.main__heading--image-paired {
	@include respond-to($nav-port-change) {
		padding-right: $profile-photo-size + 30px;
	}
}

.main__body {
	position: relative;
	@include padding-all(math.div($internal-spacing,2));
	@include respond-to($d-vp) {
		// @include leader($internal-spacing);
		@include padding-all($internal-spacing);
		min-height: 360px;
	}
}

.main__body--alt {
	@include respond-to($d-vp) {
		@include padding-all($internal-spacing*2);
		@include padding-leader($internal-spacing*1.5);
	}
}

.main__body--no-leader {
	@include respond-to($d-vp) {
		margin-top: 0;
	}
}

.main__body--improve-leading {
	@include respond-to($d-vp) {
		@include padding-leader($internal-spacing*1.5);
		@include padding-trailer($internal-spacing*2);
		@include padding-left($internal-spacing*2);
		@include padding-right($internal-spacing*2);
	}
	@include respond-to($nav-port-change) {
		@include padding-left($internal-spacing*4);
		@include padding-right($internal-spacing*4);
	}
	@include respond-to($nav-port-change + 100px) {
		@include padding-leader($internal-spacing*2.5);
		@include padding-left($internal-spacing*6);
		@include padding-right($internal-spacing*6);
	}
}

.main__module {
	@extend .main__body;
	@include leader(math.div($internal-spacing,2));
	@include respond-to($d-vp) {
		min-height: 0;
	}
}

.main__character {
	display: none;
	@include respond-to($d-vp) {
		display: block;
		width: $character-size + 80px;
		height: $character-size + 80px;
		position: absolute;
		top: -$character-size - 80px;
		right: 0;
		z-index: -1;
		@include vendor(filter, drop-shadow(-3px -3px 3px rgba(0,0,0,0.4)));
	}
}

.main__character--alt1 {
	@media 	only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   min--moz-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   -o-min-device-pixel-ratio: 2/1) and (min-width: $d-vp),
			only screen and (   min-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   min-resolution: 192dpi) and (min-width: $d-vp),
			only screen and (   min-resolution: 2dppx) and (min-width: $d-vp) {
		html.csstransforms3d & {
			@include vendor(transform, rotateZ(12deg));
			top: -$character-size - 70px;
		}
	}
}

.main__character--alt2 {
	@media 	only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   min--moz-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   -o-min-device-pixel-ratio: 2/1) and (min-width: $d-vp),
			only screen and (   min-device-pixel-ratio: 2) and (min-width: $d-vp),
			only screen and (   min-resolution: 192dpi) and (min-width: $d-vp),
			only screen and (   min-resolution: 2dppx) and (min-width: $d-vp) {
		html.csstransforms3d & {
			@include vendor(transform, rotateZ(-10deg));
			top: -$character-size - 70px;
		}
	}
}