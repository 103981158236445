// ------------------------------------------------------------
// Stats
// ------------------------------------------------------------

.statistic {
	position: relative;
	@extend %pie-clearfix;
	text-align: center;
	padding: $internal-spacing-pixel 0;
	background-color: $ds-space-dust;
}

.statistic__value{
	@extend .as-heading-headline;
	font-weight: 600;
	color: $ds-venus;
}

.statistic__label{

}