@use 'sass:math';

// ------------------------------------------------------------
// Profiles
// ------------------------------------------------------------

.profile__image {
	width: math.div($profile-photo-size,1.5);
	height: math.div($profile-photo-size,1.5);
	display: block;
	position: relative;
	z-index: 4;
	float: left;
	vertical-align: top;
	@include space-right(20);
	@include respond-to($b-vp) {
		width: $profile-photo-size;
		height: $profile-photo-size;
	}
	@include respond-to($nav-port-change) {
		margin-top: -1 * math.div($profile-photo-size,1);
		border: 5px solid #fff;
		margin-right: -5px;
		float: right;
	}
}

// Profile teasers
// ------------------------------------------------------------

.profile-teasers {
	@include trailer(-25);
}

.profile-teasers__initial {
	position: relative;
}

.profile-teaser {
	position: relative;
	display: block;
	@extend %pie-clearfix;
}
// If it's a link
a.profile-teaser {
	@include vendor(transition, all ease-out 0.25s);
	@include link-active-styles {
		&.profile-teaser--featured {
			@include respond-to($d-vp) {
				outline-color: $ds-io;
			}
		}
		.profile-teaser__image {
			outline-color: $ds-io;
		}
	}
}

.profile-teaser-cta {
	position: relative;
	@include trailer($internal-spacing);
	object, img {
		// @include padding-all(12);
		padding-bottom: 0;
		// @include respond-to($max-vp) {
			// @include leader(7);
		// }
	}
	@include respond-to($d-vp) {
		padding: 0;
		background-color: $ds-space-dust;
		.button {
			position: absolute;
			bottom: 0;
			width: 100%;
			background-color: $ds-neptune;
			color: #fff;
			@include link-active-styles {
				background-color: $ds-venus;
			}
		}
	}
	&--pink {
		.button {
			background-color: $ds-venus;
			@include link-active-styles {
				background-color: $ds-vacuum;
			}
		}
	}
	&--green {
		.button {
			background-color: $ds-io;
			@include link-active-styles {
				background-color: $ds-vacuum;
			}
		}
	}
	&--blue {
		.button {
			background-color: $ds-neptune;
			@include link-active-styles {
				background-color: $ds-vacuum;
			}
		}
	}
	&--red {
		.button {
			background-color: $ds-mars;
			@include link-active-styles {
				background-color: $ds-vacuum;
			}
		}
	}
}

.profile-teaser-cta__hitspace {
	display: block;
}

.profile-teaser-cta__heading {
	@extend %is-uppercase;
	@include font-size($beta);
	@include leading($beta*1.2);
	@include trailer(12);
	@include leader(12);
}


.profile-teaser--featured {
	outline: 3px solid transparent;
	overflow: hidden;
	@include respond-to($b-vp) {
		@include trailer($internal-spacing);
		background-image: url(../img/bkg-profile.jpg);
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: cover;
	}
}


.profile-teaser__body {
	position: relative;
	z-index: 1;
	background-color: $ds-space-dust;
	width: 95%;
	@include padding-all(math.div($internal-spacing,2));
	@include trailer(-25);
	bottom: 50px;
	margin-left: auto;
	margin-right: auto;
	.profile-teaser--featured & {
		@extend .fill-dark-matter;
		@include respond-to($b-vp) {
			position: absolute;
			right: 0;
			bottom: 0;
			margin-bottom: 0;
			width: 85%;
			max-width: 480px;
		}
		@include respond-to($c-vp) {
			width: 80%;
			max-width: 450px;
		}
		@include respond-to($nav-port-change) {
			bottom: 20px;
			max-width: 350px;
		}
	}
}

.profile-teaser__image {
	max-width: 100%;
	height: auto;
	display: block;
	position: relative;
	z-index: 0;
	@include respond-to($c-vp) {
		outline: 3px solid transparent;
	}
	@include vendor(transition, all ease-out 0.25s);
	.profile-teaser--featured & {
		@include respond-to($b-vp) {
			max-width: 62%;
			outline: none;
		}
		@include respond-to($d-vp) {
			max-width: 72%;
		}
		@include respond-to($max-vp) {
			max-width: 56%;
		}
	}
}

.profile-teaser__name {
	@extend .as-heading-large;
	.profile-teaser--featured & {
		@extend .color-io;
	}
}

.profile-teaser__role {
	@include font-size($eta);
	@include leading($eta*1.2);
	@extend %is-uppercase;
	.profile-teaser--featured & {
		@extend .color-io;
	}
}

.profile-teaser__excerpt {
	@include font-size($eta);
	@include leading($eta*1.4);
	@include leader(8);
	color: $base-color;
	@include respond-to-max($b-vp) {
		display: none;
	}
	.profile-teaser--featured & {
		color: #fff;
	}
}

.profile-teaser__mascot {
	width: $character-size + 5px;
	height: $character-size + 5px;
	position: absolute;
	top: -($character-size + 5px);
	right: 10px;
	display: block;
	@include vendor(filter, drop-shadow(-3px -3px 3px rgba(0,0,0,0.4)));
}