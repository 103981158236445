@use 'sass:math';

// Import into root of project
@import "global/reset.scss";
@import "abstracts/_color.scss";
@import "abstracts/_mixins.scss";
@import "abstracts/_placeholders.scss";
@import "abstracts/_settings.scss";

@import "global/typography.scss";
@import "global/webfonts.scss";
@import "global/base.scss";

@import "vendor/_awesomewall.scss";
@import "vendor/_bugfix.scss";
@import "vendor/_rangeslider-js.scss";
@import "components/_0_sizing.scss";
@import "components/_0_spacing-padding.scss";
@import "components/_alerts.scss";
@import "components/_aside.scss";
@import "components/_backgrounds.scss";
@import "components/_benefits.scss";
@import "components/_buttons.scss";
@import "components/_color.scss";
@import "components/_dom.scss";
@import "components/_footer.scss";
@import "components/_form-elements.scss";
@import "components/_forms.scss";
@import "components/_grid.scss";
@import "components/_header.scss";
@import "components/_heroes.scss";
@import "components/_ico.scss";
@import "components/_listings.scss";
@import "components/_lists.scss";
@import "components/_logos.scss";
@import "components/_main.scss";
@import "components/_maps.scss";
@import "components/_mason.scss";
@import "components/_media.scss";
@import "components/_navigation.scss";
@import "components/_pagination.scss";
@import "components/_profiles.scss";
@import "components/_ratios.scss";
@import "components/_resources.scss";
@import "components/_rulers.scss";
@import "components/_search.scss";
@import "components/_sections.scss";
@import "components/_sharing.scss";
@import "components/_social-hub.scss";
@import "components/_statistic.scss";
@import "components/_svg.scss";
@import "components/_tables.scss";
@import "components/_tool.scss";
@import "components/_training.scss";
@import "components/_typography-additions.scss";


// From old pattern lib

html {
	font-size: 62.5%;
	‑webkit‑text‑size‑adjust: 100%;
}

body {
	font-family: 'Source Sans Pro', Helvetica, sans-serif;
	@include font-size($base-font-size);
	@include leading($base-font-size);
	background: $ds-vacuum;
	color: $base-color;
	font-weight: 400;
	text-rendering: optimizeLegibility;
	// Grim safari hack for animation text oddness. Probably the best of a bad bunch
	-webkit-font-smoothing: subpixel-antialiased;
	@include respond-to($d-vp) {
		font-weight: 300;
	}
	@include respond-to($d-vp) {
		background: $body-color;
	}
	// @media screen and (min-width: ($max-vp + 100px)) and (min-height: 800px) {
	// 	background-image: url(/assets/svg/characters/astronaut-female-special.svg), url(/assets/svg/characters/astronaut-male-special.svg);
	// 	background-repeat: no-repeat, no-repeat;
	// 	background-position: 0 ($hero-full-height + 50px), 100% ($hero-full-height + 150px);
	// 	background-size: 260px 260px, 300px 300px;
	// 	background-attachment: fixed;
	// 	&.body-simple {
	// 		background-image: none;
	// 	}
	// }
	// @media screen and (min-width: ($max-vp + 450px)) and (min-height: 800px) {
	// 	background-position: 5% ($hero-full-height + 50px), 95% ($hero-full-height + 150px);
	// }
}

/* ::Print */
/* ------------------------------------------------------------ */

@media print {
	body {
		font-size: percentage(math.div(10,16));
	}
	*[role="navigation"] {
		display: none;
	}
}