// ------------------------------------------------------------
// Colours and fills
// Add more to the array to populate consistent names across
// colors, fills (aka background) and rules (aka borders)
// ------------------------------------------------------------

@each $number, $color in 
		('white', #fff),
		('io', $ds-io),
		('venus', $ds-venus),
		('neptune', $ds-neptune),
		('solar', $ds-solar),
		('mars', $ds-mars),
		('vacuum', $ds-vacuum),
		('vacuum-lighter', $ds-vacuum-lighter),
		('dark-matter', $ds-dark-matter),
		('space-dust', $ds-space-dust),
	{
	html > body .color-#{$number} {
		color: $color;
	}
	html > body .fill-#{$number} {
		background-color: $color;
	}
	html > body .rule--#{$number} {
		border-color: $color;
	}
	html > body .aside-m__titlebar--#{$number} {
		background-color: $color;
		&:after {
			background-color: $color;
		}
	}
}

// Ones we only need once
@each $number, $color in 
		('facebook', $facebook-color),
		('twitter', $twitter-color),
		('instagram', $instagram-color),
		('pinterest', $pinterest-color),
	{
	html > body .fill-#{$number} {
		background-color: $color;
	}
}
