// Text highlight
// ------------------------------------------------------------

.m-highlight {
    background-color: $ds-solar;
    padding: 0 2px;
}

// Search
// ------------------------------------------------------------

.m-search {
    padding: $internal-spacing-pixel 0;
    .navigation-primary__item & {
    	padding-top: 0;
    }
    .form-label{
    	@include a11yhide;
    }
    form {
        position: relative;
        button {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}