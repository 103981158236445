@use 'sass:math';

.awesomewall-post {;
	margin: 0;
	display: block;
	border: 5px solid #fff;
	@include maintain-ratio(4 2);
	width: 50%; 
	@include respond-to($b-vp) {
		@include maintain-ratio(4 1);
		width: 33.33333%; 
	}
}

.awesomewall-credit {
	//display: none!important;
}

.awesomewall-more-button {
	@extend .button;
	@extend .button--trigger;
	@include leader(math.div($internal-spacing,2));
	border: 5px solid #fff!important; // Dirty
}

.awesomewall-resolver {
	height: 20px;
	right: 10px;
	top: 10px;
	width: 20px; 
}

.awesomewall-user {
	background: rgba(0, 0, 0, 0.6);
	font-size: 12px;
	font-size: 1.2rem;
	line-height: 20px;
	padding: 10px; 
}

.awesomewall-username {
	color: white;
	padding-left: 25px; 
}

.awesomewall-avatar {
	border-radius: 50%;
	height: 20px;
	left: 10px;
	top: 10px;
	width: 20px; 
}


/* Layout and positioning */
.awesomewall-user {
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	z-index: 5; 
}

.awesomewall-username {
	box-sizing: border-box;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%; 
}

.awesomewall-avatar {
	background-position: center center;
	background-size: cover;
	position: absolute; 
}

.awesomewall-caption {
	display: none;
}

.awesomewall-type-text .awesomewall-caption 	{ opacity: 1; }
.awesomewall-post:hover .awesomewall-caption 	{ opacity: 1; }

.awesomewall-post {
	cursor: pointer;
	display: inline-block;
	position: relative; 
	vertical-align: top;
}

.awesomewall-single-post {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0; 
}

.awesomewall-image {
	background-position: center center;
	background-size: cover;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.awesomewall-resolver 				{ position: absolute; @extend .background-contain; @include vendor(filter, drop-shadow(0 0 3px rgba(0,0,0,0.4))); }
.awesomewall-resolver-instagram 	{ background-image: url(../svg/instagram-white.svg); }
.awesomewall-resolver-twitter 		{ background-image: url(../svg/twitter-white.svg); }
.awesomewall-resolver-facebook 		{ background-image: url(../svg/facebook-white.svg); }

.awesomewall-mode-fade .awesomewall-single-post {
	-webkit-transition: 500ms all;
	transition: 500ms all;
	opacity: 0; 
}

.awesomewall-mode-fade .active { opacity: 1; }

.awesomewall-mode-fade .inactive { opacity: 0; }

.awesomewall-mode-flipY .awesomewall-single-post {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 3000px;
	perspective: 3000px;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition: -webkit-transform 500ms;
	transition: transform 500ms;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg); 
}

.awesomewall-mode-flipY .active {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg); 
}

.awesomewall-mode-flipY .inactive {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg); 
}

.awesomewall-mode-flipX .awesomewall-single-post {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 3000px;
	perspective: 3000px;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition: -webkit-transform 500ms;
	transition: transform 500ms;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg); 
}

.awesomewall-mode-flipX .active {
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg); 
}

.awesomewall-mode-flipX .inactive {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg); 
}