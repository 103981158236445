@use 'sass:math';

// ------------------------------------------------------------
// Core typography
// ------------------------------------------------------------

.as-heading-headline {
	@include font-size($beta);
	@include leading($base-font-size*1.75);
	@include respond-to($d-vp) {
		@include font-size($alpha);
	}
	@include respond-to($max-vp) {
		@include font-size($alpha + 6);
	}
}
.as-heading-headline--typeset-leader {
	@include leader($base-font-size);
	@include respond-to($d-vp) {
		@include leader($base-font-size*2.25);
	}
}
.as-heading-headline--typeset-trailer {
	@include trailer($base-font-size);
}

.as-heading-largest {
	@include font-size($gamma);
	@include leading($base-font-size*1.5);
}
.as-heading-largest--typeset-leader {
	@include leader($base-font-size*1.5);
}
.as-heading-largest--typeset-trailer {
	@include trailer(math.div($base-font-size,1.5));
}

.as-heading-large {
	@include font-size($delta);
	@include leading($base-font-size*1.25);
}
.as-heading-large--typeset-leader 	{
	@include leader($base-font-size*2.25);
}
.as-heading-large--typeset-trailer	{
	@include trailer(math.div($base-font-size,1.75));
}

.as-heading-small {
	@include font-size($epsilon);
	@include leading($base-font-size*1.25);
	@extend %is-uppercase;
}
.as-heading-small--typeset-leader 	{
	@include leader($base-font-size*2.25);
}
.as-heading-small--typeset-trailer 	{
	@include trailer(math.div($base-font-size,1.75));
}


// ------------------------------------------------------------
// Other text
// ------------------------------------------------------------

.as-para 			{ @include font-size($para); }
.as-para--typeset 	{ @include trailer($base-font-size); }


.as-unordered-list {
	@include unordered;
	@include padding-left(25);
	@include respond-to($nav-port-change) {
		@include padding-left(55);
	}
	li {
		@extend .as-list-item--typeset;
	}
}

.as-ordered-list {
	@include ordered;
	@include padding-left(35);
	@include respond-to($nav-port-change) {
		@include padding-left(55);
	}
	li {
		@extend .as-list-item--typeset;
	}
}

.as-nobullet-list {
	@include nobullet;
	li {
		@extend .as-list-item--typeset;
	}
}

.as-list--typeset 					{ @include trailer($para); @include leader($para); }
.as-unordered-list--typeset 		{ @extend .as-list--typeset; }
.as-ordered-list--typeset 			{ @extend .as-list--typeset; }
.as-list-item--typeset 				{ @include trailer(math.div($para,2)); }


// ------------------------------------------------------------
// Use on parent element to style elements within. E.g an article page
// ------------------------------------------------------------

.is-typeset {
	h1 {
		@extend .as-heading-largest;
		@extend .as-heading-largest--typeset-leader;
		@extend .as-heading-largest--typeset-trailer;
	}
	h2, h3, h4 {
		@extend .as-heading-large;
		@extend .as-heading-large--typeset-leader;
		@extend .as-heading-large--typeset-trailer;
	}
	h5, h6	{
		@extend .as-heading-small;
		@extend .as-heading-small--typeset-leader;
		@extend .as-heading-small--typeset-trailer;
	}
	p {
		@extend .as-para;
		@extend .as-para--typeset;
	}
	ol {
		@extend .as-ordered-list;
		@extend .as-ordered-list--typeset;
	}
	ul {
		@extend .as-unordered-list;
		@extend .as-unordered-list--typeset;
	}
	li {
		@extend .as-para;
	}
	// Tear out the initial top margin to allow things to flush together
	> h1:first-child,
	> h2:first-child,
	> h3:first-child,
	> h4:first-child,
	> h5:first-child,
	> h6:first-child  {
		margin-top: 0;
	}
	a {
		text-decoration: underline;
	}
	.profile__image + h1,
	.profile__image + h2,
	.profile__image + h3,
	.profile__image + h4,
	.profile__image + h5,
	.profile__image + h6 {
		margin-top: 0;
	}
}

.is-typeset--no-trailing {
	p, li, ul, ol {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

