@use 'sass:math';

// ------------------------------------------------------------
// Heroes
// ------------------------------------------------------------

.hero {
	position: relative;
	z-index: 0;
	color: #fff;
	overflow: hidden;
	background-color: $ds-vacuum;
}

.hero--minor {
	img {
		display: block;
		@extend %max-width;
		padding-left: $internal-spacing-percent;
		padding-right: $internal-spacing-percent;
	}
	&:after {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		content: "";
		background-color: $ds-vacuum;
		@include gradient($ds-vacuum, darken($ds-neptune, 20%), -94deg);
		opacity: 0.05;
		display: none;
	}
	@include respond-to-max($d-vp) {
		background-image: none!important; // Consider this a graceful hack to step around the background image being inline
	}
	@include respond-to($d-vp) {
		height: $hero-minor-full-height - 100px;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		img {
			display: none;
		}
		&:after {
			display: block;
		}
	}
	@include respond-to($nav-port-change) {
		height: $hero-minor-full-height;
	}
}

.hero--minor--shallow {
	display: none;
	&:after {
		opacity: 0.7;
		display: block;
	}
	@include respond-to($nav-port-change) {
		height: math.div($hero-minor-full-height,4);
		display: block;
	}
	@include respond-to($max-vp) {
		height: math.div($hero-minor-full-height,3.5);
	}
}

.hero--minor--error {
	&:after {
		opacity: 0.5;
		display: block;
		background-color: darken($error, 20%);
		@include gradient($ds-dark-matter, darken($error, 20%), -94deg);
	}
}

.hero--minor--ss-only {
	display: none;
	@include respond-to-max($b-vp) {
		display: block;
	}
}

.hero--divided {
	position: relative;
	margin-left: $internal-spacing-percent;
	margin-right: $internal-spacing-percent;
	@include respond-to-max($b-vp) {
		display: none;
	}
	@include respond-to($d-vp) {
		height: $hero-full-height - 100px;
		margin-left: 0;
		margin-right: 0;
	}
	@include respond-to($max-vp) {
		height: $hero-full-height;
	}
}

.hero__half {
	position: relative;
	@extend .background-cover;
	&:after {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		content: "";
		//background-color: $ds-vacuum;
		@include gradient($ds-vacuum, darken($ds-vacuum, 10%), -94deg);
		opacity: 0.2;
		display: block;
	}
	img {
		display: block;
		@extend %max-width;
	}
	@include respond-to-max($d-vp) {
		background-image: none!important; // Consider this a graceful hack to step around the background image being inline
	}
	@include respond-to($b-vp) {
		float: left;
		width: 50%;
		display: inline-block;
		margin-left: 0;
		margin-right: 0;
	}
	@include respond-to($d-vp) {
		height: $hero-full-height - 100px;
		img {
			display: none;
		}
	} 
	@include respond-to($max-vp) {
		height: $hero-full-height;
	}
}

.hero__half__button {
	width: 100%;
	z-index: 2;
	@include respond-to-min-max($b-vp, $d-vp) {
		min-height: 100px;
	}
	@include respond-to($d-vp) {
		position: absolute;
		top: 55%;
		bottom: auto;
		width: auto;
		max-width: 320px;
		height: 110px;
	}
	@include respond-to($nav-port-change) {
		top: 60%;
		font-size: 110%;
		height: 118px;
	}
	.hero__half--p1 & {
		background-color: $ds-neptune;
		@include link-active-styles {
			background-color: $ds-dark-matter;
		}
		@include respond-to($d-vp) {
			right: 0;
		}
		@include respond-to($nav-port-change) {
			margin-top: -118px;
		}
	}
	.hero__half--p2 & {
		@include respond-to($d-vp) {
			left: 0;
			//margin-top: -110px;
		}
	}
}

.hero__half--p1 {
	@include respond-to($d-vp) {
		border-right: 3px solid $ds-dark-matter;
	}
}

.hero__half--p2 {
	@include respond-to($d-vp) {
		border-left: 3px solid $ds-dark-matter;
	}
	&:after {
		//@include gradient($ds-neptune, darken($ds-neptune, 20%), -94deg);
		display: none;
	}
}
