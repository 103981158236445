@use 'sass:math';

// ------------------------------------------------------------
// Styles form DOM shifting elements
// ------------------------------------------------------------

html.js {
	.dom-aside {
		@include respond-to-max($d-vp) {
			display: none;
		}
	}
	.dom-shift-navigation {
		display: none;
		@include respond-to-max($d-vp) {
			.dom-aside {
				display: block;
				border-bottom: 1px solid $base-color;
				.aside-m__body {
					background-color: lighten($ds-space-dust, 3%);
					@include padding-trailer(25);
				}
			}
			.aside-m__titlebar {
				display: none;
			}
		}
		@include respond-to($d-vp) {
			display: none;
		}
	}
	.dom-shift-navigation--open {
		display: block;
	}
	
	.dom-shift-description-full {
		@include respond-to-max($b-vp) {
			display: none;
		}
	}
	.dom-shift-description {
		margin-left: $internal-spacing-percent;
		margin-right: $internal-spacing-percent;
		@include padding-all(math.div($internal-spacing,2));
		@include trailer(math.div($internal-spacing,2));
		background-color: $ds-dark-matter;
		color: #fff;
		h1 {
			color: $ds-io;
		}
		p {
			@include font-size($eta + 1);
			line-height: 1.4;
		}
		@include respond-to($b-vp) {
			display: none;
		}
	}
}