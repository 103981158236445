@use 'sass:math';

// ------------------------------------------------------------
// Navigation
// ------------------------------------------------------------


// Primary navigation
// -----------------------------------

.navigation-primary {
	display: block;
	color: #fff;
	position: relative;
	z-index: 1;
	@include vendor(transition, max-height ease-in-out 0.25s 0.05s);
	@include respond-to($nav-port-change){
		float: right;
		margin-top: $internal-spacing-pixel;
	}
	@include respond-to-max($nav-port-change) {
		overflow: hidden;
	}
}

// .navigation-primary--open {
// 	@include respond-to-max($nav-port-change) {
// 		html.js & {
// 			max-height: 800px;
// 		}
// 	}
// }

.navigation-primary__item {
	@include respond-to($nav-port-change) {
		display: inline-block;
		text-shadow: 0px 3px 3px rgba(0,0,0,0.3);
		float: left;
		@include space-right(20);
		&:last-of-type {
			margin-right: 0;
		}
	}
}

.navigation-primary__item--ss-only {
	@include respond-to($nav-port-change) {
		display: none;
	}
}

// .navigation-primary__item--bank-a {
// 	@include respond-to($nav-port-change) {
// 		float: left;
// 		@include space-right(20);
// 	}
// 	@include respond-to($max-vp) {
// 		@include space-right(30);
// 	}
// }

// .navigation-primary__item--bank-b {
// 	@include respond-to($nav-port-change) {
// 		float: right;
// 		@include space-left(20);
// 	}
// 	@include respond-to($max-vp) {
// 		@include space-left(30);
// 	}
// }

.navigation-primary__link {
	@include font-size($zeta);
	// @extend %is-uppercase;
	letter-spacing: normal;
	font-weight: 600;
	color: #fff;
	position: relative;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	@include padding-trailer(math.div($internal-spacing,3));
	@include padding-leader(math.div($internal-spacing,3));
	border-top: 1px solid #fff;
	display: block;
	z-index: 10; // safari weirdness fix
	&:before {
		display: none;
		width: 25px;
		height: 25px;
		content: "";
		@extend .as-svg;
		@extend .svg-rocket1;
		position: absolute;
		z-index: 10000; // safari weirdness fix
		top: 200px;
		left: 100%;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		@include vendor(transition, all ease-out 0.25s 0.1s);
		@include vendor(transform, rotateZ(0deg));
		@include vendor(transform-origin, 12px 12px);
	}
	@include link-active-styles {
		color: #fff;
		text-decoration: underline;
	}
	@include respond-to($nav-port-change) {
		display: block;
		@include font-size($delta);
		@include padding-leader($internal-spacing*2.25);
		padding-bottom: 0;
		border-top: 0;
		&:before {
			display: block;
		}
		@include link-active-styles {
			&:before {
				top: 30px;
				left: 0%;
				@include vendor(transform, rotateZ(32deg));
			}
			text-decoration: none;
		}
	}
	@include respond-to($max-vp) {
		display: block;
		letter-spacing: 0.5px;
	}
}

.navigation-primary__item--current {
	.navigation-primary__link {
		color: $ds-io;
		@include respond-to($nav-port-change) {
			color: #fff;
			&:before {
				top: 30px;
				left: 0%;
				@include vendor(transform, rotateZ(32deg));
			}
		}
	}
}

.navigation-primary-toggle {
	display: block;
	background-color: transparent;
	color: #fff;
	position: absolute;
	z-index: 10;
	top: 22px;
	right: $internal-spacing-percent;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;
	padding: 10px 0;
	&:before, &:after {
		content: "";
		position: absolute;
		height: 2px;
		width: 44px;
        background-color: #fff;
        transition: all, ease-in-out, 0.3s;
        border-radius: 5px;
	}
	&:before {
		top: 0;
		left: 0;
	}
	&:after {
		bottom: 0;
        left: 0;
    }
	@include respond-to($nav-port-change) {
		display: none;
	}
}

.navigation-primary-toggle--open {
	span {
        opacity: 0;
    }
    &:before {
        transform: rotate(45deg);
        width: 44px;
        top: 20px;
       	left: 2px;
    }
    &:after {
        transform: rotate(-45deg);
        width: 44px;
        bottom: 20px;
        left: 2px;
	}
}


.navigation-primary__items {
	position: relative;
	transition: max-height ease-in-out 0.25s;
	@include respond-to-max($nav-port-change) {
		html.js & {
			max-height: 0;
		}
	}
}

.navigation-primary__items--open {
	html.js & {
		max-height: 800px;
		width: 100%;
		left: 0;
	}
}

// Jump navigation
// -----------------------------------

.navigation-jump {
	@extend .button;
	font-size: 100%;
	@include padding-leader(math.div($internal-spacing,1.5));
	@include padding-trailer(math.div($internal-spacing,1.5));
	@include padding-left(math.div($internal-spacing,2));
	@include padding-right(math.div($internal-spacing,2));
	text-align: left;
	display: none;
	background-color: $ds-solar;
	color: $heading-color;
	@include link-active-styles {
		background-color: $ds-solar;
		color: $heading-color;
	}
	&:after {
		display: block;
		@extend .ico; 
		@extend .ico--14;
		@extend .as-svg;
		@extend .svg-icon-plus-alt;
		position: absolute;
		top: 50%;
		margin-top: -7px;
		right: 1em;
		content: "";
		@include vendor(transition, all ease-out 0.15s 0.1s);
	}
	@include respond-to-max($d-vp) {
		display: block;
	}
}

.navigation-jump--open {
	&:after {
		@include vendor(transition, all ease-in-out 0.15s);
		@include vendor(transform, rotate3d(0,0,1,135deg));
		@include vendor(transform-origin, center center);
	}
}