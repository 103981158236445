// Form defaults
// Over-ride/delete as needed
// ------------------------------------------------------------

::-webkit-input-placeholder 	{ color: $form-placeholder-color; }
:-moz-placeholder 				{ color: $form-placeholder-color; }
::-moz-placeholder 				{ color: $form-placeholder-color; }
:-ms-input-placeholder 			{ color: $form-placeholder-color; }

label, legend, select {
	display: block;
	color: $form-label-color;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="submit"],
button {
	@include vendor(appearance, none);
	border-radius: 0;
}

button {
	border: none;
}

select, input, textarea, button {
	font-family: 'Source Sans Pro', Helvetica, sans-serif;
	border-radius: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
	font-family: inherit;
}

legend {
	@include font-size($form-legend-size);
}

label {
	@include font-size($form-label-size);
}

fieldset {
	margin: 0;
	display: block;
	width: 100%;
	clear: both;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
	width: 100%;
}

input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	*margin-top: 0;
	line-height: normal;
	cursor: pointer;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"],
input[type="submit"]
button {
	width: auto;
}

select,
input[type="file"] {
	*margin-top: 4px; /* In IE7, the height of the select element cannot be changed by height, only font-size */
}

select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: thin dotted #333;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}